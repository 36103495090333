import { createContext, useContext, useMemo } from "react"

import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { JSONContent } from "@tiptap/core"

import { drawers } from "../../state"
import { PostHandlerModal } from "./PostHandlerModal"
import { usePostForm } from "../../hooks/usePostForm"
import { usePostEditor } from "../../hooks/editor/usePostEditor"
import { usePostMutations } from "../../hooks/mutations/usePostMutations"
import { ruid } from "../../lib/ruid"
import { PermissionType } from "../../api/post/postCreate"
import { toast } from "@raisesistemas/ds"

type PostHandlerContextProps = {
  isLoading: boolean
  form: ReturnType<typeof usePostForm>
  editor: ReturnType<typeof usePostEditor>
  submit: () => void
  isValidContent: () => boolean
  content: JSONContent
}

const PostHandlerContext = createContext<PostHandlerContextProps>(
  {} as PostHandlerContextProps
)

const INITIAL_CONTENT = "<h2 data-placeholder></h2><p data-placeholder></p>"

export function PostHandlerProvider() {
  const { props, close } = drawers.post.handler
  const { post, plansId } = props
  const { id: defaultChannelId } = useParams()
  const channelId = post?.channelId ?? defaultChannelId

  const { t } = useTranslation()
  const form = usePostForm({
    ...post,
    channelId,
    plansId,
    postId: post?.id || "",
    permissionType: post?.permissionType as PermissionType
  })

  const editor = usePostEditor({
    content: post?.content || INITIAL_CONTENT,
    onUpdate: ({ editor }) => {
      if (!editor) return

      if (editor.isEmpty) {
        return editor.commands.setNode("heading", { level: 2 })
      }

      const json = editor.getJSON()
      const title = json?.content?.[0].content?.[0].text
      form.setFieldValue("title", title || "")
      form.clearFieldError("content")
    }
  })

  const postMutations = usePostMutations()

  const edit = postMutations.update

  const add = postMutations.create

  const isEdit = !!post?.id

  const postId = useMemo(() => post?.id ?? ruid(), [post?.id])

  const isLoading = isEdit ? edit.isPending : add.isPending

  const content = editor?.getJSON() || {
    type: "doc",
    content: []
  }

  const handleEdit = () => {
    if (!post) return
    const { values } = form

    edit.mutate(
      {
        id: postId,
        data: {
          thumbnail: values.thumbnail,
          title: values.title,
          content
        },
        channelId: values.channelId,
        plansId: values.plansId,
        permissionType: values.permissionType
      },
      { onSuccess: close }
    )
  }

  const handleAdd = () => {
    add.mutate(
      { ...form.values, content, id: ruid() },
      {
        onSuccess: () => {
          form.reset()
          editor?.commands.setContent("")
          close()
        }
      }
    )
  }

  function isValidContent() {
    if (!editor) return false

    form.validate()

    const isInvalid =
      editor.isEmpty ||
      editor.state.doc.childCount < 2 ||
      !editor.getText().trim()

    if (isInvalid) {
      form.setFieldError("content", t("validation.required_field"))
      return false
    }

    return form.isValid()
  }

  function submit() {
    if (
      form.values.permissionType === "select_plans" &&
      form.values.plansId.length === 0
    ) {
      toast.error({
        message: "Selecione pelo menos um plano",
        id: "select_plans"
      })
      return
    }
    if (!isValidContent()) return
    return isEdit ? handleEdit() : handleAdd()
  }

  return (
    <PostHandlerContext.Provider
      value={{ isLoading, form, editor, submit, isValidContent, content }}
    >
      <PostHandlerModal />
    </PostHandlerContext.Provider>
  )
}

export function usePostHandler() {
  const context = useContext(PostHandlerContext)

  if (!context) {
    throw new Error("usePostHandler must be used within PostHandlerProvider")
  }

  return context
}
