import { supabase } from "../../lib/supabase"
import { PlanType } from "../../types/plan"
import { getTenant } from "../../utils/getTenant"

export const planById = async (id: string) => {
  const { data, error } = await supabase
    .from("db")
    .select("id::text, data")
    .eq("kind", "plan_member")
    .eq("tenant", getTenant())
    .eq("id", id)
    .returns<PlanType[]>()
    .limit(1)
    .single()

  if (error) throw error

  return data
}
