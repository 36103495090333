import { t } from "i18next"
import {
  DsBox,
  DsButtonPrimary,
  DsButtonSecondary,
  DsGroup,
  DsSpace,
  DsStackVertical,
  DsText
} from "@raisesistemas/ds"
import { JSONContent } from "@tiptap/core"

import { Sheet } from "../Sheet"
import { drawers } from "../../state"
import { useStep } from "../../hooks/useStep"
import { PostHandlerContent } from "./PostHandlerContent"
import { PostHandlerPermissions } from "./PostHandlerPermissions"

import { usePostHandler } from "./PostHandlerProvider"

export type PostHandlerModalProps = {
  post?: {
    id: string
    thumbnail?: string
    title: string
    content: JSONContent
    channelId: string
    permissionType: string
  }
  plansId?: string[]
}

export function PostHandlerModal() {
  const { isOpen, close, props } = drawers.post.handler
  const [step, { goToNextStep, goToPrevStep }] = useStep(2)
  const { post } = props

  const { isLoading, submit, isValidContent } = usePostHandler()

  const isEdit = !!post?.id

  const handleToNextStep = () => isValidContent() && goToNextStep()

  const title = (
    <DsText variant="body-1" weight="bold">
      {isEdit ? "Editar" : "Criar post"}
    </DsText>
  )

  const submitButtonText = isEdit ? "Editar" : "Publicar"

  const footer = (
    <DsBox>
      <DsSpace height={20} />
      <DsStackVertical>
        <DsGroup position={step === 1 ? "right" : "apart"}>
          {step === 2 && (
            <DsButtonSecondary onClick={goToPrevStep} disabled={isLoading}>
              {t("root.back")}
            </DsButtonSecondary>
          )}

          <DsButtonPrimary
            onClick={step === 2 ? submit : handleToNextStep}
            loading={isLoading}
          >
            {step === 2 ? submitButtonText : t("root.next")}
          </DsButtonPrimary>
        </DsGroup>
      </DsStackVertical>
    </DsBox>
  )

  return (
    <Sheet
      size="xl"
      opened={isOpen}
      onClose={close}
      title={title}
      variant="modal"
      footer={footer}
    >
      {step === 1 && <PostHandlerContent />}
      {step === 2 && <PostHandlerPermissions />}
    </Sheet>
  )
}
