import { useDsTheme } from "@raisesistemas/ds"

type BaseIconProps = {
  color: string
}

/**
 * Lucide Icons
 * @see https://react-icons.github.io/react-icons/icons?name=lu
 * @see https://lucide.dev/
 */
export {
  LuArrowLeft as IconArrowLeft,
  LuBell as IconBell,
  LuHome as IconHome,
  LuList as IconList,
  LuPlusCircle as IconPlusCircle,
  LuMinusCircle as IconMinusCircle,
  LuSend as IconSend,
  LuUser as IconUser,
  LuPencil as IconPencil
} from "react-icons/lu"

/**
 * Bootstrap Icons
 * @see https://react-icons.github.io/react-icons/icons?name=bs
 * @see https://icons.getbootstrap.com/
 */
export {
  BsCodeSlash as IconCodeSlash,
  BsPinFill as IconPinFill,
  BsWhatsapp as IconWhatsapp,
  BsApple as IconApple,
  BsArrowsMove as ArrowsMove,
  BsPeople as IconPeople,
  BsBookmark as IconBookMark,
  BsPersonCheck as IconPersonCheck
} from "react-icons/bs"
export { FcGoogle as IconGoogle } from "react-icons/fc"

/**
 * Fontawesome
 * @see https://fontawesome.com/
 */
export { FaLinkedinIn as IconFaLinkedinIn } from "react-icons/fa"

/**
 * Themify Icons
 * @see https://github.com/lykmapipo/themify-icons
 */
export { TfiWorld as IconTfiWorld } from "react-icons/tfi"

/**
 * Feather Icons
 * @see https://react-icons.github.io/react-icons/icons?name=fi
 * @see https://feathericons.com/
 */
export {
  FiChevronDown as IconChevronDown,
  FiChevronRight as IconChevronRight,
  FiChevronUp as IconChevronUp,
  FiEdit3 as IconEdit,
  FiLock as IconLock,
  FiLogOut as IconLogout,
  FiType as IconType,
  FiUnlock as IconUnlock
} from "react-icons/fi"

/**
 * Ionicons 5
 * @see https://react-icons.github.io/react-icons/icons?name=io5
 * @see https://ionic.io/ionicons
 */
export {
  IoChatboxOutline as IconChatboxOutline,
  IoEllipsisVertical as IconEllipsisVertical,
  IoEllipsisHorizontal as IconEllipsisHorizontal,
  IoHeart as IconHeart,
  IoHeartOutline as IconHeartOutline,
  IoImagesOutline as IconImagesOutline,
  IoLinkOutline as IconLinkOutline,
  IoPeopleOutline as IconPeopleOutline,
  IoAddCircleOutline as IconAddCircleOutline,
  IoCubeOutline as IconCubeOutline,
  IoLayersOutline as IconLayersOutline,
  IoPersonAddOutline as IconPersonAddOutline,
  IoCalendarOutline as IconCalendarOutline,
  IoTimeOutline as IconTimeOutline,
  IoLocationOutline as IconLocationOutline,
  IoMenuOutline as IconMenuOutline,
  IoAnalyticsOutline as IconAnalyticsOutline,
  IoLocationOutline as IconIoLocationOutline,
  IoCalendarOutline as IconIoCalendarOutline,
  IoLogoInstagram as IconIoLogoInstagram,
  IoDocumentTextOutline as IconDocumentTextOutline,
  IoHomeOutline as IconHomeOutline,
  IoCreateOutline as IconCreateOutline,
  IoHappyOutline as IconHappyOutline,
  IoBookmarksOutline as IconBookmarksOutline,
  IoRemoveCircleOutline as IconRemoveCircleOutline,
  IoChatbubbleEllipsesOutline as IconChat,
  IoSendOutline as IconSendRight,
  IoChatbubbleEllipsesOutline as IconDirect,
  IoCloseOutline as IconCloseOutline,
  IoMedalOutline as MedalOutline,
  IoCopy as IconCopy,
  IoLogoUsd as IconLogoUsd,
  IoLayers as IconLayers
} from "react-icons/io5"

/**
 * Ionicons 4
 * @see https://react-icons.github.io/react-icons/icons?name=io
 */
export { IoIosArrowDown as IconIosArrowDown } from "react-icons/io"
export { IoIosArrowUp as IconIosArrowUp } from "react-icons/io"

/**
 * Phosphor Icons
 * @see https://react-icons.github.io/react-icons/icons?name=pi
 * @see https://phosphoricons.com/
 */
export {
  PiCheckCircle as IconCheckCircle,
  PiCircle as IconCircle,
  PiMagnifyingGlass as IconSearch,
  PiSignOut as IconSignOut,
  PiCubeThin as IconPiCubeThin,
  PiBookmarkSimpleFill as IconBookmarkSimpleFill,
  PiBookmarkSimple as IconBookmarkSimple,
  PiGif as IconGif,
  PiGlobeLight as GlobeIcon
} from "react-icons/pi"

/**
 * VS Code Icons
 * @see https://react-icons.github.io/react-icons/icons?name=vsc
 */
export {
  VscLink as IconLink,
  VscPlayCircle as IconPlayCircle
} from "react-icons/vsc"

/**
 * Tabler Icons
 * @see https://react-icons.github.io/react-icons/icons?name=tb
 * @see https://tabler-icons.io/
 */
export {
  TbAdjustments as IconAdjustments,
  TbBolt as IconBolt,
  TbCheck as IconCheck,
  TbCircleOff as IconCircleOff,
  TbCirclePlus as IconCirclePlus,
  TbFile as IconFile,
  TbGift as IconGift,
  TbHelp as IconHelp,
  TbInfoSquareRounded as IconInfoSquareRounded,
  TbMail as IconMail,
  TbMoodHappy as IconMoodHappy,
  TbMoon as IconMoon,
  TbPlus as IconPlus,
  TbPremiumRights as IconPremiumRights,
  TbSelector as IconSelector,
  TbSettings as IconSettings,
  TbSun as IconSun,
  TbTrash as IconTrash,
  TbWorld as IconWorld,
  TbX as IconX,
  TbHandClick as IconHandClick,
  TbCloudUpload as IconCloudUpload,
  TbDownload as IconDownload
} from "react-icons/tb"

export { MdOutlineInfo as IconOutlineInfo } from "react-icons/md"
/**
 * Ant Design Icons
 * @see https://react-icons.github.io/react-icons/icons?name=ai
 * @see https://github.com/ant-design/ant-design-icons
 */
export {
  AiFillCheckCircle as FillCheckCircle,
  AiOutlineEye as EyeOutline,
  AiOutlineInfoCircle as IconOutlineInfoCircle,
  AiFillCloseCircle as FillCloseCircle
} from "react-icons/ai"

/**
 * Custom icons
 *
 *
 */

export const FeaturedLockClosed = (props: BaseIconProps) => {
  const { color } = props
  const { fn } = useDsTheme()
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4"
        y="4"
        width="48"
        height="48"
        rx="24"
        fill={fn.lighten(color, 0.9)}
      />
      <path
        d="M33.25 25H32.5V21.25C32.5 20.0565 32.0259 18.9119 31.182 18.068C30.3381 17.2241 29.1935 16.75 28 16.75C26.8065 16.75 25.6619 17.2241 24.818 18.068C23.9741 18.9119 23.5 20.0565 23.5 21.25V25H22.75C21.9546 25.0009 21.1921 25.3172 20.6296 25.8796C20.0672 26.4421 19.7509 27.2046 19.75 28V36.25C19.7509 37.0454 20.0672 37.8079 20.6296 38.3704C21.1921 38.9328 21.9546 39.2491 22.75 39.25H33.25C34.0454 39.2491 34.8079 38.9328 35.3704 38.3704C35.9328 37.8079 36.2491 37.0454 36.25 36.25V28C36.2491 27.2046 35.9328 26.4421 35.3704 25.8796C34.8079 25.3172 34.0454 25.0009 33.25 25ZM31 25H25V21.25C25 20.4544 25.3161 19.6913 25.8787 19.1287C26.4413 18.5661 27.2044 18.25 28 18.25C28.7956 18.25 29.5587 18.5661 30.1213 19.1287C30.6839 19.6913 31 20.4544 31 21.25V25Z"
        fill={color}
      />
      <rect
        x="4"
        y="4"
        width="48"
        height="48"
        rx="24"
        stroke={fn.lighten(color, 0.95)}
        strokeWidth="8"
      />
    </svg>
  )
}

export const LockOpen = (props: BaseIconProps) => {
  const { color } = props

  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.875 7.5H8V4.375C8 3.71196 8.26339 3.07607 8.73223 2.60723C9.20107 2.13839 9.83696 1.875 10.5 1.875C11.163 1.875 11.7989 2.13839 12.2678 2.60723C12.7366 3.07607 13 3.71196 13 4.375C13 4.54076 13.0658 4.69973 13.1831 4.81694C13.3003 4.93415 13.4592 5 13.625 5C13.7908 5 13.9497 4.93415 14.0669 4.81694C14.1842 4.69973 14.25 4.54076 14.25 4.375C14.25 3.38044 13.8549 2.42661 13.1517 1.72335C12.4484 1.02009 11.4946 0.625 10.5 0.625C9.50544 0.625 8.55161 1.02009 7.84835 1.72335C7.14509 2.42661 6.75 3.38044 6.75 4.375V7.5H6.125C5.46218 7.50072 4.82672 7.76435 4.35803 8.23303C3.88935 8.70172 3.62572 9.33718 3.625 10V16.875C3.62572 17.5378 3.88935 18.1733 4.35803 18.642C4.82672 19.1107 5.46218 19.3743 6.125 19.375H14.875C15.5378 19.3743 16.1733 19.1107 16.642 18.642C17.1107 18.1733 17.3743 17.5378 17.375 16.875V10C17.3743 9.33718 17.1107 8.70172 16.642 8.23303C16.1733 7.76435 15.5378 7.50072 14.875 7.5Z"
        fill={color}
      />
    </svg>
  )
}

export const LayersOutline = (props: BaseIconProps) => {
  const { color } = props
  const { fn } = useDsTheme()

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          x="2"
          y="2"
          width="32"
          height="32"
          rx="16"
          fill={fn.lighten(color, 0.85)}
        />
        <path
          d="M11.332 19.6664L17.7602 22.8805C17.8476 22.9242 17.8914 22.9461 17.9372 22.9547C17.9779 22.9623 18.0195 22.9623 18.0602 22.9547C18.106 22.9461 18.1498 22.9242 18.2372 22.8805L24.6654 19.6664M11.332 16.3331L17.7602 13.119C17.8476 13.0752 17.8914 13.0534 17.9372 13.0448C17.9779 13.0372 18.0195 13.0372 18.0602 13.0448C18.106 13.0534 18.1498 13.0752 18.2372 13.119L24.6654 16.3331L18.2372 19.5471C18.1498 19.5909 18.106 19.6127 18.0602 19.6213C18.0195 19.6289 17.9779 19.6289 17.9372 19.6213C17.8914 19.6127 17.8476 19.5909 17.7602 19.5471L11.332 16.3331Z"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="2"
          y="2"
          width="32"
          height="32"
          rx="16"
          stroke={fn.lighten(color, 0.9)}
          strokeWidth="4"
        />
      </g>
    </svg>
  )
}

export const FeaturedOutline = (props: BaseIconProps) => {
  const { color } = props
  const { fn } = useDsTheme()

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          x="2"
          y="2"
          width="32"
          height="32"
          rx="16"
          fill={fn.lighten(color, 0.7)}
        />
        <path
          d="M18.6633 11.333L12.7256 18.4582C12.4931 18.7373 12.3768 18.8768 12.375 18.9946C12.3735 19.0971 12.4191 19.1945 12.4988 19.2589C12.5905 19.333 12.7721 19.333 13.1353 19.333H17.9967L17.33 24.6663L23.2677 17.5411C23.5002 17.2621 23.6165 17.1225 23.6183 17.0047C23.6198 16.9023 23.5742 16.8048 23.4945 16.7404C23.4028 16.6663 23.2212 16.6663 22.858 16.6663H17.9967L18.6633 11.333Z"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="2"
          y="2"
          width="32"
          height="32"
          rx="16"
          stroke={fn.lighten(color, 0.75)}
          strokeWidth="4"
        />
      </g>
    </svg>
  )
}

export const IconCoins = (props: BaseIconProps) => {
  const { color } = props
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7132 9.00991C13.5535 9.00991 15.856 7.98656 15.856 6.72419C15.856 5.46183 13.5535 4.43848 10.7132 4.43848C7.87285 4.43848 5.57031 5.46183 5.57031 6.72419C5.57031 7.98656 7.87285 9.00991 10.7132 9.00991Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.57031 6.72461V13.5818C5.57031 14.8389 7.85603 15.8675 10.7132 15.8675C13.5703 15.8675 15.856 14.8389 15.856 13.5818V6.72461"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8571 10.1529C15.8571 11.41 13.5714 12.4386 10.7143 12.4386C7.85714 12.4386 5.57143 11.41 5.57143 10.1529M10.6 2.15289C9.26456 1.32875 7.71009 0.930163 6.14286 1.01003C3.29714 1.01003 1 2.0386 1 3.29574C1 3.97003 1.66286 4.57574 2.71429 5.01003"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.71429 11.8673C1.66286 11.433 1 10.8273 1 10.153V3.2959"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.71429 8.4389C1.66286 8.00461 1 7.3989 1 6.72461"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconNotificationList = (props: BaseIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.125 5.5C3.85978 5.5 3.60543 5.60536 3.41789 5.79289C3.23036 5.98043 3.125 6.23478 3.125 6.5C3.125 6.76522 3.23036 7.01957 3.41789 7.20711C3.60543 7.39464 3.85978 7.5 4.125 7.5C4.39022 7.5 4.64457 7.39464 4.83211 7.20711C5.01964 7.01957 5.125 6.76522 5.125 6.5C5.125 6.23478 5.01964 5.98043 4.83211 5.79289C4.64457 5.60536 4.39022 5.5 4.125 5.5ZM4.125 4.5C4.022 4.5 3.922 4.508 3.823 4.523C4.01482 4.07119 4.3354 3.68584 4.74476 3.41501C5.15412 3.14418 5.63416 2.99984 6.125 3H15.125C15.788 3 16.4239 3.26339 16.8928 3.73223C17.3616 4.20107 17.625 4.83696 17.625 5.5V14.5C17.625 15.163 17.3616 15.7989 16.8928 16.2678C16.4239 16.7366 15.788 17 15.125 17H6.125C5.46196 17 4.82607 16.7366 4.35723 16.2678C3.88839 15.7989 3.625 15.163 3.625 14.5V8.437C3.95298 8.5215 4.29702 8.5215 4.625 8.437V14.5C4.625 14.8978 4.78304 15.2794 5.06434 15.5607C5.34564 15.842 5.72718 16 6.125 16H15.125C15.5228 16 15.9044 15.842 16.1857 15.5607C16.467 15.2794 16.625 14.8978 16.625 14.5V5.5C16.625 5.10218 16.467 4.72064 16.1857 4.43934C15.9044 4.15804 15.5228 4 15.125 4H6.125C5.612 4 5.159 4.258 4.888 4.65C4.64597 4.55041 4.38671 4.49944 4.125 4.5ZM7.625 8.5C7.625 8.36739 7.67768 8.24021 7.77145 8.14645C7.86521 8.05268 7.99239 8 8.125 8H13.125C13.2576 8 13.3848 8.05268 13.4786 8.14645C13.5723 8.24021 13.625 8.36739 13.625 8.5C13.625 8.63261 13.5723 8.75979 13.4786 8.85355C13.3848 8.94732 13.2576 9 13.125 9H8.125C7.99239 9 7.86521 8.94732 7.77145 8.85355C7.67768 8.75979 7.625 8.63261 7.625 8.5ZM8.125 11C7.99239 11 7.86521 11.0527 7.77145 11.1464C7.67768 11.2402 7.625 11.3674 7.625 11.5C7.625 11.6326 7.67768 11.7598 7.77145 11.8536C7.86521 11.9473 7.99239 12 8.125 12H11.125C11.2576 12 11.3848 11.9473 11.4786 11.8536C11.5723 11.7598 11.625 11.6326 11.625 11.5C11.625 11.3674 11.5723 11.2402 11.4786 11.1464C11.3848 11.0527 11.2576 11 11.125 11H8.125Z"
        fill={props.color}
      />
    </svg>
  )
}

export const IconAI = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.237 3.887l.08.04c.323.166.586.43.753.753l.04.08c.16.317.613.317.777 0l.04-.08a1.75 1.75 0 01.753-.753l.08-.04a.436.436 0 000-.777l-.08-.04a1.75 1.75 0 01-.753-.753l-.04-.08a.436.436 0 00-.777 0l-.04.08a1.75 1.75 0 01-.753.753l-.08.04a.436.436 0 000 .777zM6.867 7.01a.446.446 0 000-.797l-.487-.25a2.346 2.346 0 01-1.01-1.01l-.25-.486a.446.446 0 00-.797 0l-.25.486a2.345 2.345 0 01-1.01 1.01l-.486.25a.446.446 0 000 .797l.486.25c.434.223.787.577 1.01 1.01l.25.487c.167.326.63.326.797 0l.25-.487a2.346 2.346 0 011.01-1.01l.487-.25zM13.12 9.003l-.493-.253a3.123 3.123 0 01-1.377-1.377l-.253-.493a1.3 1.3 0 00-1.164-.713 1.3 1.3 0 00-1.163.713l-.253.493A3.123 3.123 0 017.04 8.75l-.493.253a1.3 1.3 0 00-.714 1.164c0 .493.274.94.714 1.163l.493.253c.597.304 1.073.78 1.377 1.377l.253.493a1.3 1.3 0 001.163.714 1.3 1.3 0 001.164-.714l.253-.493a3.123 3.123 0 011.377-1.377l.493-.253a1.3 1.3 0 00.713-1.163 1.3 1.3 0 00-.713-1.164zm-.453 1.437l-.494.253a4.112 4.112 0 00-1.813 1.814l-.253.493a.3.3 0 01-.274.167A.3.3 0 019.56 13l-.253-.493a4.112 4.112 0 00-1.814-1.814L7 10.44a.3.3 0 01-.167-.273A.3.3 0 017 9.893l.493-.253a4.111 4.111 0 001.814-1.813l.253-.494a.3.3 0 01.273-.166.3.3 0 01.274.166l.253.494a4.112 4.112 0 001.813 1.813l.494.253a.3.3 0 01.166.274.3.3 0 01-.166.273z"
        fill="#000"
      />
    </svg>
  )
}
export const IconAiEssential = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.20508C6.38 2.20508 2.75 5.44508 2.75 10.4551C2.75 15.4651 6.38 18.7051 12 18.7051C12.04 18.7051 12.08 18.7051 12.12 18.7051C12.08 19.1151 11.98 19.5651 11.82 20.0501C11.64 20.5901 11.82 21.1651 12.265 21.5151C12.51 21.7051 12.795 21.8001 13.08 21.8001C13.325 21.8001 13.57 21.7301 13.79 21.5901C15.615 20.4301 16.745 19.0751 17.165 17.5551C19.765 16.1701 21.245 13.6051 21.245 10.4601C21.245 5.45008 17.615 2.21008 11.995 2.21008L12 2.20508ZM16.195 16.3651C15.98 16.4651 15.83 16.6651 15.785 16.8951C15.565 18.0201 14.735 19.1101 13.385 20.0601C13.605 19.2601 13.685 18.5201 13.62 17.8551C13.58 17.4701 13.255 17.1751 12.875 17.1751C12.855 17.1751 12.84 17.1751 12.82 17.1751C12.555 17.1951 12.285 17.2051 12.005 17.2051C7.225 17.2051 4.255 14.6201 4.255 10.4551C4.255 6.29008 7.225 3.70508 12.005 3.70508C16.785 3.70508 19.755 6.29008 19.755 10.4551C19.755 13.1701 18.495 15.2651 16.2 16.3651H16.195Z"
        fill="#101113"
      />
      <path
        d="M10.87 10.1551L10.805 10.1251C10.555 9.99508 10.35 9.79008 10.22 9.54008L10.19 9.47508C10.065 9.23008 9.715 9.23008 9.59 9.47508L9.56 9.54008C9.43 9.79008 9.225 9.99508 8.975 10.1251L8.91 10.1551C8.665 10.2801 8.665 10.6301 8.91 10.7551L8.975 10.7851C9.225 10.9151 9.43 11.1201 9.56 11.3701L9.59 11.4351C9.715 11.6801 10.065 11.6801 10.19 11.4351L10.22 11.3701C10.35 11.1201 10.555 10.9151 10.805 10.7851L10.87 10.7551C11.115 10.6301 11.115 10.2801 10.87 10.1551Z"
        fill="#101113"
      />
      <path
        d="M8 10.0351L7.91 9.99008C7.56 9.81008 7.27 9.52508 7.09 9.17008L7.045 9.08008C6.87 8.73508 6.38 8.73508 6.205 9.08008L6.16 9.17008C5.98 9.52008 5.695 9.81008 5.34 9.99008L5.25 10.0351C4.905 10.2101 4.905 10.7001 5.25 10.8751L5.34 10.9201C5.69 11.1001 5.98 11.3851 6.16 11.7401L6.205 11.8301C6.38 12.1751 6.87 12.1751 7.045 11.8301L7.09 11.7401C7.27 11.3901 7.555 11.1001 7.91 10.9201L8 10.8751C8.345 10.7001 8.345 10.2101 8 10.0351Z"
        fill="#101113"
      />
      <path
        d="M18.29 9.02508L18.13 8.94508C17.63 8.69008 17.23 8.29008 16.975 7.79008L16.895 7.63008C16.62 7.09008 16.07 6.75508 15.465 6.75508C14.86 6.75508 14.31 7.09008 14.03 7.63008L13.95 7.79008C13.695 8.29008 13.295 8.69008 12.795 8.94508L12.635 9.02508C12.095 9.30008 11.76 9.85008 11.76 10.4601C11.76 11.0701 12.095 11.6151 12.635 11.8951L12.795 11.9751C13.295 12.2301 13.695 12.6301 13.95 13.1301L14.03 13.2901C14.305 13.8301 14.855 14.1651 15.465 14.1651C16.075 14.1651 16.62 13.8301 16.9 13.2901L16.98 13.1301C17.235 12.6301 17.635 12.2301 18.135 11.9751L18.295 11.8951C18.835 11.6201 19.17 11.0701 19.17 10.4651C19.17 9.86008 18.835 9.31008 18.295 9.03008L18.29 9.02508ZM17.61 10.5551L17.45 10.6351C16.665 11.0351 16.04 11.6601 15.64 12.4451L15.56 12.6051C15.56 12.6051 15.53 12.6651 15.465 12.6651C15.4 12.6651 15.375 12.6201 15.37 12.6051L15.29 12.4451C14.89 11.6601 14.265 11.0351 13.48 10.6351L13.32 10.5551C13.32 10.5551 13.26 10.5251 13.26 10.4601C13.26 10.3951 13.305 10.3701 13.32 10.3651L13.48 10.2851C14.265 9.88508 14.89 9.26008 15.29 8.47508L15.37 8.31508C15.37 8.31508 15.4 8.25508 15.465 8.25508C15.53 8.25508 15.555 8.29508 15.56 8.31508L15.64 8.47508C16.04 9.26008 16.665 9.88508 17.45 10.2851L17.61 10.3651C17.61 10.3651 17.67 10.3951 17.67 10.4601C17.67 10.5251 17.625 10.5501 17.61 10.5551Z"
        fill="#101113"
      />
    </svg>
  )
}
