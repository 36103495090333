import { ReactNode, memo } from "react"
import { MemberInfo } from "../../Member/MemberInfoV2"

import { useDateDistanceToNow } from "../../../hooks/useDateDistanceToNow"

type PostHeaderProps = {
  postId: string
  memberId: string
  memberName: string
  memberAvatar: string

  hideAction?: boolean
  rightSection?: ReactNode
  disableClick?: boolean
}

export const PostHeader = memo((props: PostHeaderProps) => {
  const {
    postId,
    memberId,
    memberName,
    memberAvatar,
    rightSection,
    disableClick
  } = props
  const { dateDistanceToNow } = useDateDistanceToNow()

  return (
    <MemberInfo>
      <MemberInfo.Avatar
        memberId={memberId}
        name={memberName}
        avatar={memberAvatar}
        disableClick={disableClick}
      />
      <MemberInfo.Content>
        <MemberInfo.Name
          memberId={memberId}
          name={memberName}
          disableClick={disableClick}
        />

        <MemberInfo.Description text={dateDistanceToNow(postId)} />
      </MemberInfo.Content>
      {rightSection}
    </MemberInfo>
  )
})
