import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"

type PostFixedPayload = {
  channelId: string
  postId: string
}

export const postFixedRemove = async (payload: PostFixedPayload) => {
  const { channelId, postId } = payload

  const { error } = await supabase
    .from("db")
    .update({
      kind: KINDS.POST_FIXED_TRASH
    })
    .eq("parent_id", postId)
    .eq("child_id", channelId)
    .eq("kind", KINDS.POST_FIXED)

  if (error) throw error
}
