import { DropdownItensProps } from "@raisesistemas/ds"
import { PostModel } from "../../../types/post"
import { drawers } from "../../../state"

import { useFixedPost } from "../../../hooks/post/useFixedPost"
import { useTranslation } from "react-i18next"
import { useUserPermissions } from "../../../hooks/permissions/useUserPermissions"
import { useLocation, useParams } from "react-router-dom"
import { useShowPost } from "./useShowPost"
import { queries } from "../../../queries"
import { useQuery } from "@tanstack/react-query"

type MenuItensProps = {
  post: PostModel
  handleOpenDelete: () => void
}
export const useMenuItens = (props: MenuItensProps) => {
  const { post, handleOpenDelete } = props
  const params = useParams()
  const { t } = useTranslation()
  const { can } = useUserPermissions()
  const { pathname } = useLocation()
  const canEditPost = can("update", "post", { post })
  const canDeletePost = can("delete", "post", { post })
  const canPinPost = can("create", "pin_post")
  const postUniquePath = `/posts/${post.id}`
  const isPostPage = pathname === postUniquePath
  const canSeePostAction = canEditPost || canDeletePost || canPinPost
  const channelId = params?.id || ""

  const { handleToggle, handleUnpin } = useFixedPost(post)
  const isEdit = !!post.id
  const { data: plans } = useQuery({
    ...queries.planPosts.listByPostId(post.id || ""),
    enabled: isEdit
  })

  const plansId = plans?.map(plan => plan.parent_id) || []

  const handleOpenDrawer = () => {
    drawers.post.handler.open({
      post: {
        ...post.data,
        id: post.id,
        channelId: post.channel?.id || "",
        permissionType: post.permissionType
      },
      plansId
    })
  }

  const { handleShowPost } = useShowPost({ post })

  const dropdownItens: DropdownItensProps[] = [
    {
      icon: " ",
      name: t("root.see_post"),
      onClick: handleShowPost,
      withDivider: false,
      visible: !isPostPage
    },
    {
      icon: " ",
      name: post.fixed ? t("button.unpin_post") : t("button.pin_post"),
      onClick: handleToggle,
      withDivider: false,
      visible: canPinPost && Boolean(channelId)
    },
    {
      icon: " ",
      name: t("button.edit"),
      onClick: handleOpenDrawer,
      withDivider: true,
      visible: canEditPost
    },
    {
      icon: " ",
      color: "red",
      name: t("button.delete"),
      onClick: handleOpenDelete,
      withDivider: false,
      visible: canDeletePost
    }
  ]

  return {
    canSeePostAction,
    dropdownItens,
    handleUnpin
  }
}
