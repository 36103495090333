import { supabase } from "../../lib/supabase"
import { PostDataType } from "../../types/post"
import { planPostsUpdate } from "../planPosts/planPostsUpdate"
import { PermissionType } from "./postCreate"

export type PostUpdatePayload = {
  id: string
  data: PostDataType
  channelId?: string
  plansId?: string[]
  permissionType: PermissionType
}

export async function postUpdate(payload: PostUpdatePayload) {
  const recordPost = {
    parent_id: payload.channelId || null,
    data: {
      thumbnail: payload.data.thumbnail,
      title: payload.data.title,
      content: payload.data.content
    },
    permission_type: payload.permissionType
  }

  const { error } = await supabase
    .from("db")
    .update(recordPost)
    .eq("id", payload.id)
  if (error) throw new Error(error.message)

  if (payload.plansId && payload.plansId.length > 0 && payload.id) {
    await planPostsUpdate({ plansId: payload.plansId, postId: payload.id })
  }
}
