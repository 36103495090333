import {
  DsBox,
  DsCenter,
  DsDrawerProps,
  createStyles,
  DsDrawer,
  DsText
} from "@raisesistemas/ds"
import { BottomSheet, BottomSheetProps } from "../BottomSheet"
import { useIsScreen } from "../../hooks/useIsScreen"
import { Modal } from "@mantine/core"

type SheetProps = DsDrawerProps &
  Pick<BottomSheetProps, "adjustToContentHeight" | "footerHeight"> & {
    showOverlay?: boolean
    zIndex?: number
  } & {
    testId?: string
    hideFooter?: boolean
    variant?: "drawer" | "modal"
  }

export const Sheet = (props: SheetProps) => {
  const {
    showOverlay = true,
    zIndex,
    title,
    testId,
    hideFooter = false,
    footer,
    variant = "drawer",
    ...rest
  } = props

  const { cx, classes } = useStyles()
  const { isDesktop, isMobile } = useIsScreen()

  const sheetFooter = hideFooter ? null : footer

  return (
    <DsCenter>
      {isDesktop && (
        <DsBox
          className={cx({
            [classes.removeOverlay]: !showOverlay
          })}
          data-testid={testId}
        >
          {variant === "drawer" && (
            <DsDrawer
              zIndex={zIndex}
              withOverlay={showOverlay}
              title={
                // TODO: adicionar a prop 'component' no DsText.
                <DsText variant="heading-3" weight="semi-bold">
                  {title}
                </DsText>
              }
              {...rest}
              footer={sheetFooter}
            />
          )}

          {variant === "modal" && (
            // TODO: Ajustar o DsModal
            <Modal
              {...rest}
              title={title}
              size="xl"
              centered
              radius="lg"
              padding="lg"
              zIndex={10000}
            >
              {rest.children}
              {sheetFooter}
            </Modal>
          )}
        </DsBox>
      )}

      {isMobile && (
        <BottomSheet
          data-testid={testId}
          title={title}
          footer={sheetFooter}
          {...rest}
        />
      )}
    </DsCenter>
  )
}

const useStyles = createStyles({
  removeOverlay: {
    ".mantine-Overlay-root": {
      opacity: 0
    }
  }
})
