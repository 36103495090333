import {
  DsAspectRatio,
  DsButtonPrimary,
  DsContainer,
  DsGroup,
  DsSpace,
  DsStackVertical,
  DsText,
  createStyles
} from "@raisesistemas/ds"
import { PlanType } from "../../../types/plan"
import { formatMoneyInCents } from "../../../utils/formatMoneyInCents"
import { PostContent } from "../../../components/post/PostItem/PostContent"
import { Sheet } from "../../../components/Sheet"
import { useIsScreen } from "../../../hooks/useIsScreen"
import { drawers } from "../../../state"
import { useSubscriptionQueries } from "../../../hooks/queries/useSubscriptionQueries"

export type PlanDetailsProps = {
  plan: PlanType
}

export const PlanDetails = () => {
  const {
    isOpen,
    close,
    props: { plan }
  } = drawers.plan.detail
  const { classes } = useStyles()
  const { isMobile } = useIsScreen()
  const { data: subscription } = useSubscriptionQueries().findUnique()

  const hasUserSubscribed = (planId: string): boolean => {
    return subscription?.parent_id === planId
  }

  const isSubscribed = hasUserSubscribed(plan.id)

  function handleInsertCPF() {
    drawers.plan.insertCPF.open({ planId: plan.id })
    close()
  }

  const title = (
    <DsText variant="heading-3" weight="bold">
      {plan.data.description}
    </DsText>
  )

  const footer = (
    <DsButtonPrimary
      onClick={() => handleInsertCPF()}
      //@ts-ignore
      w="100%"
      disabled={isSubscribed}
    >
      {isSubscribed ? "Assinado" : "Assinar"}
    </DsButtonPrimary>
  )

  return (
    <>
      <Sheet
        opened={isOpen}
        onClose={close}
        title={title}
        footer={footer}
        variant={isMobile ? "modal" : "drawer"}
      >
        <DsContainer style={{ padding: 0 }}>
          {plan.data.image && (
            <DsAspectRatio ratio={16 / 8}>
              <img
                src={plan?.data.image}
                style={{
                  borderRadius: "16px 16px 16px 16px"
                }}
              />
            </DsAspectRatio>
          )}

          <DsSpace height={15} />

          <DsStackVertical className={classes.card} spacing={15}>
            <DsGroup position="center">
              <DsText variant="heading-3" weight="semi-bold">
                {`${formatMoneyInCents(plan.data.price_cents)}/${
                  plan.data.interval === "months" ? "Mês" : "Ano"
                }`}
              </DsText>
            </DsGroup>

            <DsText variant="body-1" weight="semi-bold">
              Descrição:
            </DsText>
          </DsStackVertical>
          {plan.data.info && <PostContent content={plan.data.info} />}
        </DsContainer>
      </Sheet>
    </>
  )
}

const useStyles = createStyles(({ colorScheme, colors }) => {
  const isDark = colorScheme === "dark"
  return {
    container: {
      padding: 0,
      borderRadius: 16,
      border: `2px solid ${isDark ? colors.dark[4] : colors.gray[2]}`,
      marginBottom: 20
    },
    card: {
      padding: "0px 10px"
    }
  }
})
