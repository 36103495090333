import { formatMoney } from "./formatMoney"

export const formatMoneyInCents = (value: number) => {
  const realValue = value / 100

  if (Number.isInteger(realValue)) {
    return `R$${realValue}`
  } else {
    return formatMoney(realValue)
  }
}
