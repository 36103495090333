import { useTranslation } from "react-i18next"
import { DsSpace, DsStackVertical } from "@raisesistemas/ds"

import { PostEditor } from "./PostEditor"
import { usePostHandler } from "./PostHandlerProvider"
import { ImagePreviewUpload } from "../ImagePreviewUpload"

export function PostHandlerContent() {
  const { t } = useTranslation()
  const { form, editor } = usePostHandler()
  return (
    <DsStackVertical spacing="sm">
      <ImagePreviewUpload
        title={t("root.add_post_thumbnail")}
        fullPreview={true}
        fullWidth={true}
        aspectRatio={2.8}
        image={form.values.thumbnail}
        imageDimensionsLabel="840 x 300px"
        imageSizeLabel="10mb"
        handleImage={image => form.setFieldValue("thumbnail", image)}
      />

      <PostEditor editor={editor} error={form.errors?.content as string} />

      <DsSpace height={80} />
    </DsStackVertical>
  )
}
