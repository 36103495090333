import { AspectRatio } from "@mantine/core"
import { memo } from "react"

type PostThumbnailProps = {
  thumbnail?: string
}

export const PostThumbnail = memo((props: PostThumbnailProps) => {
  const { thumbnail } = props

  if (!thumbnail) return
  return (
    <AspectRatio ratio={2.8}>
      <img width="100%" height="100%" src={thumbnail} />
    </AspectRatio>
  )
})
