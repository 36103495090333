import { MS_PAYMENTS_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"
import { uploadImageBase64 } from "../upload/uploadImageBase64"

export type PlanCreatePayload = {
  type: string
  price_cents: number
  active: boolean
  interval: string
  description: string
  interval_count: number
  trial_period_days: number
  fee_paid_by_member: boolean
  image?: string
  info: object
}

export const planCreate = async (payload: PlanCreatePayload) => {
  const { access_token } = await getSession()

  let image: string | undefined

  if (payload.image) {
    const url = await uploadImageBase64(payload.image, {
      maxWidth: 1600,
      maxHeight: 900,
      quality: 1
    })

    image = url || undefined

    if (!image) throw new Error("Image upload failed")
  }

  const body = { ...payload, image, tenant: getTenant() }

  const response = await fetch(`${MS_PAYMENTS_URL}/create-plan`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })

  if (!response.ok) throw new Error("Error creating plan")
}
