import { MS_PAYMENTS_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"
import { uploadImageBase64 } from "../upload/uploadImageBase64"

type PlanUpdatePayload = {
  id: string
  price_cents: number
  description: string
  image?: string
  info?: object
}

export const planUpdate = async (payload: PlanUpdatePayload) => {
  const endpoint = `${MS_PAYMENTS_URL}/update-plan`
  const { access_token } = await getSession()

  let image: string | undefined

  if (payload.image) {
    const url = await uploadImageBase64(payload.image, {
      maxWidth: 1600,
      maxHeight: 900,
      quality: 1
    })

    image = url || undefined

    if (!image) throw new Error("Image upload failed")
  }

  const body = {
    ...payload,
    image,
    tenant: getTenant()
  }

  const response = await fetch(endpoint, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
}
