import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { planPostsCreate } from "./planPostsCreate"

export type PostUpdatePayload = {
  plansId: string[]
  postId: string
}

export async function planPostsUpdate(payload: PostUpdatePayload) {
  const tenant = getTenant()

  const { error: deleteError } = await supabase
    .from("db")
    .update({ kind: "trash_plan_post" })
    .match({ tenant, child_id: payload.postId, kind: "plan_post" })

  if (deleteError) throw deleteError

  await planPostsCreate({ plansId: payload.plansId, postId: payload.postId })
}
