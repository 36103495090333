import { useMutation, useQueryClient } from "@tanstack/react-query"
import { subscriptionCancel } from "../../api/subscription/subscriptionCancel"
import { queries } from "../../queries"

export const useSubscriptionMutations = () => {
  const queryClient = useQueryClient()

  const cancel = useMutation({
    mutationFn: subscriptionCancel,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.subscription.findUnique().queryKey
      })
    }
  })
  return {
    cancel
  }
}
