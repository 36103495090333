import {
  DsButtonUnstyled,
  DsFlex,
  DsStackVertical,
  DsText
} from "@raisesistemas/ds"
import { Sheet } from "../../../../components/Sheet"
import { IconLogout, IconPencil } from "../../../../icons"
import { useNav } from "../../../../hooks/useNav"
import { drawers } from "../../../../state"
import { useSubscriptionQueries } from "../../../../hooks/queries/useSubscriptionQueries"

export const PaymentEditPlanSheet = () => {
  const { isOpen, close } = drawers.subscription.edit
  const { data: subscription } = useSubscriptionQueries().findUnique()
  const navigate = useNav()
  const isBlocked = subscription?.data.active

  function handleCancelDrawer() {
    drawers.subscription.cancel.open()
    drawers.subscription.edit.close()
  }

  function handleEditDrawer() {
    drawers.subscription.edit.open()
    navigate("/settings/plan")
    drawers.subscription.edit.close()
  }

  return (
    <>
      <Sheet
        opened={isOpen}
        onClose={close}
        title="Opções do plano"
        variant={"modal"}
      >
        <DsStackVertical styles={{ marginTop: 40 }}>
          <DsButtonUnstyled onClick={() => handleEditDrawer()}>
            <DsFlex gap={12}>
              <IconPencil size={24} />
              <DsText variant="body-1" weight="regular">
                Alterar plano
              </DsText>
            </DsFlex>
          </DsButtonUnstyled>
          {/* <DsButtonUnstyled>
            <DsFlex gap={12}>
              <IconPencil size={24} />
              <DsText variant="body-1" weight="regular">
                Editar método de pagamento
              </DsText>
            </DsFlex>
          </DsButtonUnstyled> */}
          {isBlocked && (
            <DsButtonUnstyled onClick={() => handleCancelDrawer()}>
              <DsFlex gap={12}>
                <IconLogout size={24} color="red" />
                <DsText variant="body-1" weight="regular" color="red">
                  Cancelar plano
                </DsText>
              </DsFlex>
            </DsButtonUnstyled>
          )}
        </DsStackVertical>
      </Sheet>
    </>
  )
}
