import { JSONContent } from "@tiptap/core"
import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { planPostsCreate } from "../planPosts/planPostsCreate"

export type PermissionType =
  | "public"
  | "all_members"
  | "only_members_paid"
  | "select_plans"

export type PostCreatePayload = {
  thumbnail: string
  title: string
  content: JSONContent
  user_uuid: string
  channelId: string
  id?: string
  plansId?: string[]
  permissionType: PermissionType
}

export async function postCreate(payload: PostCreatePayload) {
  const tenant = getTenant()

  const recordPost = {
    id: payload.id,
    tenant,
    kind: KINDS.POST,
    parent_id: payload.channelId || undefined,
    data: {
      title: payload.title,
      thumbnail: payload.thumbnail,
      content: payload.content
    },
    user_uuid: payload.user_uuid,
    permission_type: payload.permissionType
  }

  const { error } = await supabase.from("db").insert(recordPost)

  if (error) throw error

  if (payload.plansId && payload.plansId.length > 0 && payload.id) {
    await planPostsCreate({ plansId: payload.plansId, postId: payload.id })
  }
}
