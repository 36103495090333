import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

export type PostCreatePayload = {
  plansId: string[]
  postId: string
}

export async function planPostsCreate(payload: PostCreatePayload) {
  const tenant = getTenant()
  const userId = await getUserId()

  const records = payload.plansId.filter(Boolean).map(planId => ({
    parent_id: planId,
    child_id: payload.postId,
    tenant,
    user_uuid: userId,
    kind: "plan_post"
  }))

  const { error } = await supabase.from("db").insert(records)

  if (error) throw error
}
