import {
  DsBox,
  DsFlex,
  DsGroup,
  DsText,
  createStyles,
  rem
} from "@raisesistemas/ds"
import { JSONContent } from "@tiptap/core"
import { PostContent } from "./PostContent"
import { PostThumbnail } from "./PostThumbnail"
import { memo } from "react"
import { Spoiler } from "../../Spoiler"
import { useSpoiler } from "./useSpoiler"
import { useUser } from "../../../hooks/useUser"
import { PostHeader } from "./PostHeader"
import { ruid } from "../../../lib/ruid"
import { IconHeart } from "@tabler/icons-react"
import { IconChatboxOutline } from "../../../icons"
import { random } from "../../../utils/random"

type PostItemProps = {
  post: {
    thumbnail: string
    content: JSONContent
  }
}

export const PostPreview = memo((props: PostItemProps) => {
  const { post } = props

  const { data, user_uuid } = useUser()
  const { classes } = useStyles()
  const { spoilerSize } = useSpoiler()

  return (
    <DsBox className={classes.container}>
      <DsBox className={classes.spacing}>
        <PostHeader
          postId={ruid()}
          memberId={user_uuid}
          memberName={data.name}
          memberAvatar={data.avatar}
          disableClick
        />
      </DsBox>

      <PostThumbnail thumbnail={post.thumbnail} />
      <DsBox className={classes.spacing}>
        <Spoiler maxHeight={spoilerSize} onShow={() => {}}>
          <PostContent content={post.content} />
        </Spoiler>
      </DsBox>

      <DsGroup position="apart" style={{ padding: 12 }}>
        <DsFlex align="center" gap={6}>
          <IconChatboxOutline size={20} color="gray" />
          <DsText variant="body-3" weight="semi-bold">
            {random.number(10, 100)}
          </DsText>
        </DsFlex>

        <DsFlex align="center" gap={6}>
          <IconHeart color="#FC2323" size={20} />
          <DsText variant="body-3" weight="semi-bold">
            {random.number(100, 1000)}
          </DsText>
        </DsFlex>
      </DsGroup>
    </DsBox>
  )
})

const useStyles = createStyles(({ colorScheme, colors }) => {
  const isDark = colorScheme === "dark"
  return {
    container: {
      flex: 1,
      overflow: "hidden",
      border: `1px solid ${isDark ? colors.dark[4] : colors.gray[2]}`,
      borderRadius: rem(8)
    },
    spacing: {
      paddingTop: rem(20),
      paddingBottom: rem(20),
      paddingLeft: rem(16),
      paddingRight: rem(16)
    }
  }
})
