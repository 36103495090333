import { RouteObject } from "react-router-dom"
import { PrivateRoutesRules } from "./PrivateRoutesRules"
import { DashLayout } from "../../components/layouts/DashLayout"

export const privateRoutes: RouteObject = {
  element: <PrivateRoutesRules />,
  children: [
    {
      path: "/finish-register",
      lazy: async () => {
        const { PageFinishRegister } = await import(
          "../../pages/finish-register/PageFinishRegister"
        )
        return { Component: PageFinishRegister }
      }
    },
    {
      element: <DashLayout />,
      children: [
        {
          path: "/channels",
          lazy: async () => {
            const { PageChannels } = await import(
              "../../pages/channel/PageChannels"
            )
            return { Component: PageChannels }
          }
        },
        {
          path: "/channels/:id/posts",
          lazy: async () => {
            const { PageChannelPosts } = await import(
              "../../pages/channel/PageChannelPosts/PageChannelPosts"
            )
            return { Component: PageChannelPosts }
          }
        },
        {
          path: "/channels/:id/posts/new",
          lazy: async () => {
            const { PageChannelPostsNew } = await import(
              "../../pages/channel/PageChannelPostsNew"
            )
            return { Component: PageChannelPostsNew }
          }
        },
        {
          path: "/channels/posts",
          lazy: async () => {
            const { PageChannelAllPosts } = await import(
              "../../pages/channel/PageChannelAllPosts"
            )
            return { Component: PageChannelAllPosts }
          }
        },
        {
          path: "/events",
          lazy: async () => {
            const { PageEvents } = await import("../../pages/events/PageEvents")
            return { Component: PageEvents }
          }
        },

        {
          path: "/members",
          lazy: async () => {
            const { PageMembers } = await import(
              "../../pages/members/PageMembers"
            )
            return { Component: PageMembers }
          }
        },
        {
          path: "/messages",
          lazy: async () => {
            const { PageMessages } = await import(
              "../../pages/messages/PageMessages"
            )
            return { Component: PageMessages }
          }
        },
        {
          path: "/metrics",
          lazy: async () => {
            const { PageMetrics } = await import(
              "../../pages/metrics/PageMetrics"
            )
            return { Component: PageMetrics }
          }
        },
        {
          path: "/notifications",
          lazy: async () => {
            const { PageNotifications } = await import(
              "../../pages/PageNotifications"
            )
            return { Component: PageNotifications }
          }
        },
        {
          path: "/posts/:postId",
          lazy: async () => {
            const { PageChannelPost } = await import(
              "../../pages/channel/PageChannelPost"
            )
            return { Component: PageChannelPost }
          }
        },
        {
          path: "/posts/saved",
          lazy: async () => {
            const { PagePostsSaved } = await import(
              "../../pages/posts/saved/PagePostsSaved"
            )
            return { Component: PagePostsSaved }
          }
        },
        {
          path: "/redirect",
          lazy: async () => {
            const { PageRedirect } = await import("../../pages/PageRedirect")
            return { Component: PageRedirect }
          }
        },
        {
          path: "/settings",
          lazy: async () => {
            const { PageSettings } = await import(
              "../../pages/settings/PageSettings"
            )
            return { Component: PageSettings }
          }
        },
        {
          path: "/settings/community",
          lazy: async () => {
            const { PageSettingsCommunity } = await import(
              "../../pages/settings/PageCommunity"
            )
            return { Component: PageSettingsCommunity }
          }
        },
        {
          path: "/settings/language",
          lazy: async () => {
            const { PageLanguage } = await import(
              "../../pages/settings/PageLanguage"
            )
            return { Component: PageLanguage }
          }
        },
        {
          path: "/settings/members/:memberId",
          lazy: async () => {
            const { PageMember } = await import(
              "../../pages/settings/PageMember"
            )
            return { Component: PageMember }
          }
        },
        {
          path: "/settings/payment",
          lazy: async () => {
            const { PagePayment } = await import(
              "../../pages/settings/Payment/PagePayment"
            )
            return { Component: PagePayment }
          }
        },
        {
          path: "/settings/payment-account",
          lazy: async () => {
            const { PagePaymentAccount } = await import(
              "../../pages/settings/Payment/PaymentAccount/PagePaymentAccount"
            )
            return { Component: PagePaymentAccount }
          }
        },
        {
          path: "/settings/plan",
          lazy: async () => {
            const { PagePlan } = await import(
              "../../pages/settings/Plan/PagePlan"
            )
            return { Component: PagePlan }
          }
        },
        {
          path: "/settings/profile",
          lazy: async () => {
            const { PageSettingsProfile } = await import(
              "../../pages/settings/PageSettingsProfile/PageSettingsProfile"
            )
            return { Component: PageSettingsProfile }
          }
        },
        {
          path: "/settings/create-plan",
          lazy: async () => {
            const { PageCreatePlan } = await import(
              "../../pages/settings/Plan/PageCreatePlan"
            )
            return { Component: PageCreatePlan }
          }
        },
        {
          path: "/settings/plans/:planId/edit",
          lazy: async () => {
            const { PageEditPlan } = await import(
              "../../pages/settings/Plan/PageEditPlan"
            )
            return { Component: PageEditPlan }
          }
        },
        {
          path: "/spaces/create",
          lazy: async () => {
            const { PageSpaceCreate } = await import(
              "../../pages/space/PageSpaceCreate"
            )
            return { Component: PageSpaceCreate }
          }
        }
      ]
    }
  ]
}
