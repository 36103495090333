import { modals } from "@mantine/modals"
import { toast } from "@raisesistemas/ds"
import { PreCheckoutContent } from "../PreCheckoutContent"
import { useTranslation } from "react-i18next"
import { capitalize } from "../../utils/capitalize"
import { usePlanQueries } from "../../hooks/queries/usePlanQueries"

export const useRequestAccessModal = () => {
  const { t } = useTranslation()
  const { data } = usePlanQueries().find()

  const requestAccessModal = (type: string) => {
    if (!Boolean(data)) {
      toast.info({
        id: "no-plan",
        message: capitalize(
          t("modal.pre_checkout.no_plan", { type: t(`root.${type}`) })
        )
      })
      return
    }

    modals.open({
      modalId: "pre-checkout",
      itemID: "pre-checkout",
      title: t("modal.pre_checkout.title"),
      styles: {
        title: {
          fontWeight: "bold"
        }
      },
      children: <PreCheckoutContent />
    })
  }

  return { requestAccessModal }
}
