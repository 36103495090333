import { useMutation, useQueryClient } from "@tanstack/react-query"
import { planCreate } from "../../api/plan/planCreate"
import { queries } from "../../queries"
import { planAndBankCreate } from "../../api/plan/planAndBankCreate"
import { toast } from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { planDestroy } from "../../api/plan/planDestroy"
import { capitalize } from "../../utils/capitalize"
import { planUpdate } from "../../api/plan/planUpdate"
import { useNav } from "../useNav"

export const usePlanMutations = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNav()

  const create = useMutation({
    mutationFn: planCreate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.plans._def })
      toast.success({ message: t("root.plan_added_successfully") })
      navigate("/settings/plan")
    }
  })

  const createPlanAndBank = useMutation({
    mutationFn: planAndBankCreate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.plans._def })
      toast.success({
        id: "create-plan-success",
        message: t("root.info_saved")
      })
    },
    onError: () => {
      toast.error({
        id: "create-plan-error",
        message: t("error.message.cant_finalize")
      })
    }
  })

  const destroy = useMutation({
    mutationFn: planDestroy,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.plans._def })
      toast.success({
        id: "destroy-plan-error",
        message: capitalize(t("common.deleted", { type: t("root.plan") }))
      })
    },
    onError: () => {
      toast.error({
        id: "destroy-plan-error",
        message: t("error.message.cant_finalize")
      })
    }
  })

  const update = useMutation({
    mutationFn: planUpdate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.plans._def })
      toast.success({
        id: "update-plan-error",
        message: capitalize(t("common.updated", { type: t("root.plan") }))
      })
      navigate("/settings/plan")
    },
    onError: () => {
      toast.error({
        id: "update-plan-error",
        message: t("error.message.cant_finalize")
      })
    }
  })

  return {
    create,
    destroy,
    update,
    createPlanAndBank
  }
}
