import { supabase } from "../../lib/supabase"
import { PlanType } from "../../types/plan"
import { getTenant } from "../../utils/getTenant"

export const planList = async () => {
  const { data, error } = await supabase
    .from("db")
    .select("id::text, data, subscriptionsCount:db!parent_id(count)")
    .eq("kind", "plan_member")
    .eq("tenant", getTenant())
    .eq("subscriptionsCount.kind", "subscription_member")
    .order("id")
    .returns<PlanType[]>()

  if (error) throw error

  const parsedData = data.map(plan => ({
    ...plan,
    data: {
      ...plan.data,
      subscriptionCount: plan.subscriptionsCount[0].count || 0
    }
  }))

  return parsedData
}
