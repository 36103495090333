import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"
import { createInfiniteService } from "../../utils/createInfiniteService"
import { PostModel, PostType } from "../../types/post"
import { parsePost } from "../../utils/parsePost"

type PostSavedType = {
  post: PostType
}

export const postSavedList = createInfiniteService<unknown, PostModel>(
  async ({ rangeEnd, rangeStart }) => {
    const userId = await getUserId()

    const { data, error, count } = await supabase
      .from("db")
      .select(
        `
        id::text,
        post:parent_id!inner(
          *,
          id::text,
          member(user_uuid, data, role),
          saved:db!parent_id(id::text),
          liked:db!parent_id(id::text),
          likesCount:db!parent_id(count),
          commentsCount:db!parent_id(count),
          comments:db!parent_id(
            id::text,
            user_uuid,
            data->content,
            postId:parent_id::text,
            member(data->name,data->avatar),
            repliesCount:db!parent_id(count),
            likesCount:db!parent_id(count),
            likes:db!parent_id(id::text)
          ),
          channel:parent_id(
            id::text,
            kind,
            data
          )
        )
      `,
        { count: "exact", head: false }
      )
      .eq("tenant", getTenant())
      .eq("kind", KINDS.POST_SAVED)
      .eq("user_uuid", userId)
      .eq("post.liked.kind", KINDS.POST_LIKE)
      .eq("post.likesCount.kind", KINDS.POST_LIKE)
      .eq("post.saved.kind", KINDS.POST_SAVED)
      .in("post.kind", [KINDS.POST, KINDS.POST_FIXED])
      .eq("post.liked.user_uuid", userId)
      .eq("post.commentsCount.kind", KINDS.POST_COMMENT)
      .eq("post.comments.kind", KINDS.POST_COMMENT)
      .eq("post.comments.repliesCount.kind", KINDS.COMMENT_REPLY)
      .eq("post.comments.likesCount.kind", KINDS.COMMENT_LIKE)
      .eq("post.comments.likes.kind", KINDS.COMMENT_LIKE)
      .eq("post.comments.likes.user_uuid", userId)
      .eq("post.channel.kind", KINDS.CHANNEL)
      .range(rangeStart, rangeEnd)
      .limit(1, { foreignTable: "post.comments" })
      .order("id")
      .returns<PostSavedType[]>()

    if (error) throw error

    const _data = data.map(({ post }) => parsePost(post, false))

    return {
      data: _data,
      error,
      count
    }
  }
)
