import { DsBox, DsGroup, DsText, createStyles } from "@raisesistemas/ds"
import { EyeOutline } from "../../icons"
import { useEffect, useState } from "react"
import { useThemeColoring } from "../../hooks/useThemeColoring"

type BalanceValueProps = {
  value: string
  updatedAt: string
  storageKey: string
}

type inputType = "text" | "password"

const loadFromStorage = (storageKey: string): inputType => {
  const type = localStorage.getItem(storageKey)
  if (type) return type as inputType
  return "text"
}

export const BalanceValue: React.FC<BalanceValueProps> = props => {
  const { value, updatedAt, storageKey } = props
  const { classes } = useStyles()
  const { lightDark } = useThemeColoring()
  const [inputType, setInputType] = useState<inputType>(
    loadFromStorage(storageKey)
  )

  useEffect(() => {
    localStorage.setItem(storageKey, inputType)
  }, [inputType])

  const changeInputType = () =>
    setInputType(prevInputType =>
      prevInputType === "text" ? "password" : "text"
    )

  return (
    <DsGroup spacing={8}>
      <DsBox className={classes.fieldWrapper}>
        <input
          type={inputType}
          value={value}
          readOnly
          className={classes.input}
          style={{ background: "transparent" }}
        />

        <button
          type="button"
          className={classes.toggleHide}
          onClick={() => changeInputType()}
        >
          <EyeOutline />
        </button>
      </DsBox>

      <DsText
        variant="body-2"
        color={lightDark("gray.5", "gray.5")}
        weight="regular"
      >
        {updatedAt}
      </DsText>
    </DsGroup>
  )
}

const useStyles = createStyles(({ colorScheme, colors }) => ({
  fieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorScheme == "dark" ? colors.dark[4] : "#EAECF0",
    borderRadius: 12
  },
  input: {
    width: "100%",
    border: 0,
    outline: "unset",
    cursor: "no-drop",
    fontWeight: 600,
    fontSize: 20
  },
  toggleHide: {
    background: "transparent",
    border: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  }
}))
