import { createStyles } from "@raisesistemas/ds"

export const useStyles = createStyles(({ colors, colorScheme, white, fn }) => {
  const bg = colorScheme === "dark" ? colors.dark[7] : white
  return {
    spoiler: {
      width: "100%",
      height: "300px",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "end",
      padding: "20px",
      background: `linear-gradient(0deg, ${bg} 0%, ${fn.rgba(
        bg,
        0.98
      )} 20%, rgba(255,255,255,0) 100%)`
    }
  }
})
