import { useForm } from "@mantine/form"
import { JSONContent } from "@tiptap/core"

import { useAuth } from "./useAuth"
import { PermissionType } from "../api/post/postCreate"

type InitialValues = {
  thumbnail?: string
  title?: string
  content?: JSONContent
  channelId?: string
  plansId?: string[]
  postId?: string
  permissionType?: PermissionType
}

export const usePostForm = (initialValues?: InitialValues) => {
  const { user } = useAuth()

  const form = useForm({
    initialValues: {
      thumbnail: initialValues?.thumbnail || "",
      title: initialValues?.title || "",
      content: initialValues?.content || "",
      channelId: initialValues?.channelId || "",
      user_uuid: user!.user_uuid,
      plansId: initialValues?.plansId || [],
      postId: initialValues?.postId || null,
      permissionType: initialValues?.permissionType || "public"
    }
  })

  return form
}
