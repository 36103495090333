import { KINDS } from "../../lib/constants"
import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

type PostFixedPayload = {
  channelId: string
  postId: string
}

export const postFixedCreate = async (payload: PostFixedPayload) => {
  const { channelId, postId } = payload
  const userId = await getUserId()

  const { data } = await supabase
    .from("db")
    .select("id::text")
    .eq("tenant", getTenant())
    .eq("kind", KINDS.POST_FIXED_TRASH)
    .eq("parent_id", postId)
    .eq("child_id", channelId)
    .single()

  if (data?.id) {
    await supabase
      .from("db")
      .update({ kind: KINDS.POST_FIXED })
      .eq("id", data.id)

    return
  }

  const { error } = await supabase.from("db").insert({
    kind: KINDS.POST_FIXED,
    parent_id: postId,
    child_id: channelId,
    user_uuid: userId,
    tenant: getTenant()
  })

  if (error) throw error
}
