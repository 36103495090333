import { MS_PAYMENTS_URL } from "../../env"
import { getSession } from "../auth/getSession"
import { formatBankBody } from "./formatters/bank-payload"

export type BankUpdatePayload = {
  name: string
  account_type: string
  bank: string
  document: string
  bank_ag: string
  bank_cc: string
  bank_account_type: string
  phone?: string
}

export const bankUpdate = async (payload: BankUpdatePayload) => {
  const { access_token } = await getSession()
  const url = `${MS_PAYMENTS_URL}/update-bank-account`

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(formatBankBody(payload))
  })

  if (!response.ok) throw new Error(response.statusText)
}
