import { MS_PAYMENTS_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"

type CheckoutUrlPayload = {
  document: string
  planId: string
}

export const checkoutUrl = async ({ document, planId }: CheckoutUrlPayload) => {
  const { access_token, user } = await getSession()
  const { email, user_metadata } = user
  const name = user_metadata?.name

  const url = new URL(`${MS_PAYMENTS_URL}/checkout`)
  url.searchParams.set("tenant", getTenant())
  url.searchParams.set("name", name)
  url.searchParams.set("email", email || "")
  url.searchParams.set("document", document)
  url.searchParams.set("plan_id", planId)

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })

  if (!response.ok) throw new Error(response.statusText)

  return (await response.json()) as { checkout_url: string }
}
