import {
  rem,
  DsBox,
  DsFlex,
  DsSpace,
  DsSwitch,
  DsText,
  DsTextInputBase,
  DsTextInputMoney,
  DsTooltip
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { UseFormReturnType } from "@mantine/form"

import { IconOutlineInfo } from "../../../icons"
import { price2cents } from "../../../utils/price2cents"
import { formatMoney } from "../../../utils/formatMoney"
import { FEE_PERCENTAGE } from "../../../lib/constants"
import { capitalize } from "../../../utils/capitalize"

type PlanForm = {
  description: string
  interval_count: number
  interval: string
  price_cents: string
  type: string
  active: boolean
  trial_period_days: number
  fee_paid_by_member: boolean
  image: string
  info: object
}

type PlanFormProps = {
  form: UseFormReturnType<PlanForm>
}

export const PlanFormOld = (props: PlanFormProps) => {
  const { form } = props
  const { t } = useTranslation()

  const { fee_paid_by_member, price_cents } = form.values
  const priceCents = price2cents(price_cents)

  const fee = (priceCents * FEE_PERCENTAGE) / 100
  const totalReceivableWithFee = priceCents - fee

  const totalReceivable =
    (fee_paid_by_member ? priceCents : totalReceivableWithFee) / 100

  return (
    <DsBox sx={{ paddingBottom: rem(24), paddingTop: rem(24) }}>
      <DsText weight="medium" variant="body-1">
        {t("root.create_pay_plan")}
      </DsText>

      <DsSpace height="sm" />

      <DsText weight="regular" color="dimmed" variant="body-3">
        {t("root.create_pay_plan_description")}
      </DsText>

      <DsSpace height="sm" />

      <DsTextInputBase
        withAsterisk
        label={t("root.plan_name")}
        placeholder={t("root.insert_name_plan")}
        {...form.getInputProps("description")}
      />

      <DsSpace height="xl" />

      <DsBox>
        <DsText weight="medium" variant="body-2">
          {t("root.your_members_will_pay")}
        </DsText>

        <DsSpace height="sm" />

        <DsBox
          sx={({ primaryColor, colors }) => ({
            backgroundColor: colors[primaryColor][0],
            color: colors[primaryColor][5],
            display: "inline-block",
            borderRadius: 4,
            padding: `${rem(8)} ${rem(24)}`
          })}
        >
          <DsText weight="medium" variant="body-2">
            {t("root.recurrent")}
          </DsText>
        </DsBox>
      </DsBox>

      <DsSpace height="xl" />

      <DsTextInputBase
        label={t("root.recurrence_type")}
        placeholder={capitalize(t("common.monthly"))}
        disabled
      />

      <DsSpace height="sm" />

      <DsTextInputMoney
        required
        label={t("root.price")}
        placeholder="R$ 10,90"
        description={t("root.member_plan_price_helper")}
        inputWrapperOrder={["label", "input", "error", "description"]}
        {...form.getInputProps("price_cents")}
      />

      <DsSpace height="sm" />

      <DsTextInputBase
        value={formatMoney(totalReceivable)}
        label={t("root.total_receivable")}
        placeholder="R$ 10,90"
        description={`${t("root.transaction_fee")} ${FEE_PERCENTAGE}%`}
        inputWrapperOrder={["label", "input", "description"]}
        disabled
      />

      <DsSpace height="lg" />

      <DsFlex align="center" gap={16}>
        <DsSwitch
          label={t("root.pass_the_transaction_fee")}
          {...form.getInputProps("fee_paid_by_member")}
        />

        <DsTooltip
          label={t("tooltip.pass_the_transaction_fee")}
          multiline
          maxWidth={310}
        >
          <IconOutlineInfo />
        </DsTooltip>
      </DsFlex>
    </DsBox>
  )
}
