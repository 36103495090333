import {
  DsButtonSecondary,
  DsDrawer,
  DsGroup,
  DsSpace,
  DsStackVertical,
  DsText
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"

import { PostModel } from "../../types/post"
import { PostView } from "./PostView"
import { drawers } from "../../state"

export type PostViewDrawerProps = {
  post: PostModel
}

export const PostViewDrawer = () => {
  const { isOpen, close, props } = drawers.post.view
  const { post } = props
  const { t } = useTranslation()
  return (
    <DsDrawer
      opened={isOpen}
      onClose={close}
      title={
        <DsText weight="bold" variant="body-1">
          {t("root.post")}
        </DsText>
      }
      footer={
        <DsGroup position="right">
          <DsButtonSecondary onClick={close}>Fechar</DsButtonSecondary>
        </DsGroup>
      }
    >
      <DsStackVertical>
        <PostView post={post} />
      </DsStackVertical>

      <DsSpace height={100} />
    </DsDrawer>
  )
}
