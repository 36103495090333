import { useTranslation } from "react-i18next"
import { BalanceValue } from "./BalanceValue"
import {
  createStyles,
  DsButtonPrimary,
  DsContainer,
  DsGroup,
  DsMediaQuery,
  DsStackVertical,
  DsText
} from "@raisesistemas/ds"
import { useBalanceQueries } from "../../hooks/queries/useBalanceQueries"
import { BalanceSkeleton } from "../Skeletons/BalanceSkeleton"
import { formatMoneyInCents } from "../../utils/formatMoneyInCents"
import { STORAGE_KEYS } from "../../lib/constants"
import { drawers } from "../../state"

type BalanceDisplayProps = {
  showButtonHeading?: boolean | null
  showSmartButton?: boolean | null
  direction?: "column" | "row"
}

export const BalanceDisplay: React.FC<BalanceDisplayProps> = props => {
  const { showSmartButton = false, direction = "column" } = props

  const { t } = useTranslation()
  const balanceQueries = useBalanceQueries()
  const { classes } = useStyles()
  const {
    balance,
    hasProcessingWithdraw,
    balanceUpdatedAt,
    balanceLastWithdrawUpdatedAt
  } = balanceQueries.balanceOwner()

  const openExtractDrawer = () => drawers.extract.full.open()

  if (!balance) return <BalanceSkeleton />

  return (
    <DsStackVertical>
      <DsGroup noWrap={direction == "row"}>
        <DsContainer className={classes.container}>
          <DsMediaQuery largerThan="md" styles={{ flexWrap: "nowrap" }}>
            <DsGroup position="apart">
              <DsStackVertical spacing="xs">
                <DsText variant="body-1" weight="semi-bold" color="dimmed">
                  {t("root.balance_available")}
                </DsText>

                <BalanceValue
                  value={formatMoneyInCents(balance.balance_cents)}
                  updatedAt={balanceUpdatedAt}
                  storageKey={STORAGE_KEYS.BALANCE_AVAILABLE_TYPE}
                />
              </DsStackVertical>

              {showSmartButton && !hasProcessingWithdraw && (
                <DsButtonPrimary onClick={openExtractDrawer}>
                  {t("root.see_extract")}
                </DsButtonPrimary>
              )}
            </DsGroup>
          </DsMediaQuery>
        </DsContainer>

        {hasProcessingWithdraw && balance.last_withdraw && (
          <DsContainer className={classes.container}>
            <DsStackVertical>
              <DsText variant="body-1" weight="semi-bold" color="dimmed">
                {t("root.withdrawal_in_processing")}
              </DsText>

              <BalanceValue
                value={formatMoneyInCents(balance.last_withdraw.value_cents)}
                storageKey={STORAGE_KEYS.BALANCE_PROCESSING_TYPE}
                updatedAt={balanceLastWithdrawUpdatedAt}
              />
            </DsStackVertical>
          </DsContainer>
        )}
      </DsGroup>
    </DsStackVertical>
  )
}

const useStyles = createStyles(({ colorScheme, colors }) => {
  const isDark = colorScheme === "dark"
  return {
    container: {
      padding: 28,
      borderRadius: 16,
      marginTop: 8,
      border: `2px solid ${isDark ? colors.dark[4] : colors.gray[2]}`,
      width: "100%"
    }
  }
})
