import { useState } from "react"
import {
  DsButtonDestructive,
  DsButtonSecondary,
  DsMediaQuery,
  DsStackVertical,
  DsText
} from "@raisesistemas/ds"
import { Sheet } from "../../../../components/Sheet"
import { useNav } from "../../../../hooks/useNav"
import { useStep } from "../../../../hooks/useStep"
import { PostContent } from "../../../../components/post/PostItem/PostContent"
import { IconCheck } from "../../../../icons"
import { useSpaceQuery } from "../../../../hooks/queries/useSpaceQuery"
import { useSubscriptionQueries } from "../../../../hooks/queries/useSubscriptionQueries"
import { useSubscriptionMutations } from "../../../../hooks/mutations/useSubscriptionMutations"
import { formatDate } from "../../../../utils/formatDate"
import { drawers } from "../../../../state"
import { useUser } from "../../../../hooks/useUser"

export const PaymentCancelPlanModal = () => {
  const { isOpen, close } = drawers.subscription.cancel
  const { data: space } = useSpaceQuery()
  const { data: subscription } = useSubscriptionQueries().findUnique()
  const { cancel } = useSubscriptionMutations()
  const { user_uuid } = useUser()
  const [step, { goToNextStep }] = useStep(2)
  const [loading, setLoading] = useState(false)
  const navigate = useNav()

  const handleCancelSubscription = () => {
    setLoading(true)
    cancel.mutate(
      { memberId: user_uuid, plan_id: subscription?.parent_id ?? "" },
      {
        onSuccess: () => {
          setLoading(false)
          goToNextStep()
        }
      }
    )
  }

  function handleCancelDrawer() {
    navigate("/settings/plan")
    drawers.subscription.cancel.close()
  }

  const title = (
    <img
      src={space?.data.logo}
      style={{ maxWidth: 50, maxHeight: 50, borderRadius: "30%" }}
    />
  )

  return (
    <Sheet opened={isOpen} onClose={close} title={title} variant="modal">
      {step === 1 && (
        <DsStackVertical>
          <DsText variant="heading-3" weight="medium">
            Confirmar cancelamento?
          </DsText>
          <DsText variant="body-1">
            Se você cancelar não terá mais acesso aos benefícios exclusivos do
            plano {subscription?.plan.data.description} depois de{" "}
            <strong>{formatDate(subscription?.data.expires_at || "")}</strong>,
            que incluem:
          </DsText>

          {subscription?.plan.data.info && (
            <PostContent content={subscription?.plan.data.info} />
          )}

          <DsMediaQuery largerThan="md" styles={{ flexDirection: "row" }}>
            <DsStackVertical spacing={16} justify="end">
              <DsButtonDestructive
                onClick={() => handleCancelSubscription()}
                loading={loading}
              >
                Confirmar cancelamento
              </DsButtonDestructive>

              <DsButtonSecondary
                onClick={() => drawers.subscription.cancel.close()}
              >
                Manter benefícios
              </DsButtonSecondary>
            </DsStackVertical>
          </DsMediaQuery>
        </DsStackVertical>
      )}

      {step === 2 && (
        <DsStackVertical align="center">
          <IconCheck size={48} color="green" />
          <DsText variant="heading-3" weight="medium">
            Cancelamento concluído
          </DsText>
          <DsText variant="body-1">
            Você ainda terá acesso aos benefícios exclusivos do plano{" "}
            {subscription?.plan.data.description} até{" "}
            <strong>{formatDate(subscription?.data.expires_at || "")}</strong>
          </DsText>

          <DsButtonSecondary
            onClick={() => handleCancelDrawer()}
            //@ts-ignore
            w="100%"
          >
            Fechar
          </DsButtonSecondary>
        </DsStackVertical>
      )}
    </Sheet>
  )
}
