import { useTranslation } from "react-i18next"
import {
  IconBell,
  IconPeople,
  IconHomeOutline,
  IconBookmarkSimple,
  IconAnalyticsOutline,
  IconCalendarOutline,
  IconDirect,
  IconAddCircleOutline
} from "../../icons"
import { drawers } from "../../state"
import { EventBadge } from "../Events/EventBadge"
import { useIsRole } from "../../hooks/useIsRole"
import { useEventQueries } from "../../hooks/queries/useEventQueries"
import { useActivityUnreadCountQuery } from "../../hooks/queries/useActivityUnreadCountQuery"
import { useActivitiesQueries } from "../../hooks/queries/useActivitiesQueries"
import { useDirectQueries } from "../../hooks/queries/useDirectQueries"
import { useCallback, useMemo } from "react"
import { NotificationBadge } from "../Notifications/NotificationBadge"
import { useLocation } from "react-router-dom"

export type NavLinkType = {
  id: string
  title: string
  icon: React.ReactNode
  to?: string
  onClick?: () => void
  badgeCount?: number
  hide?: boolean
  badge?: React.ReactNode
}

export const useNavLinks = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const { isOwner, isModerator } = useIsRole()
  const { data: unreadActivities } = useActivityUnreadCountQuery()
  const { unreadCountByType } = useActivitiesQueries()
  const { data: unreadMemberCount } = unreadCountByType("member")
  const { data: hasEventInProgress } = useEventQueries().hasEventInProgress()
  const { data: unReadMessagesCount } = useDirectQueries().count()

  const homeIcon = useMemo(() => <IconHomeOutline size={18} />, [])
  const bellIcon = useMemo(() => <IconBell size={18} />, [])
  const directIcon = useMemo(() => <IconDirect size={18} />, [])
  const calendarIcon = useMemo(() => <IconCalendarOutline size={18} />, [])
  const analyticsIcon = useMemo(() => <IconAnalyticsOutline size={18} />, [])
  const peopleIcon = useMemo(() => <IconPeople size={18} />, [])
  const bookmarkIcon = useMemo(() => <IconBookmarkSimple size={18} />, [])
  const createPostIcon = useMemo(() => <IconAddCircleOutline size={20} />, [])

  const renderBadge = useCallback((link: NavLinkType) => {
    if (link.badgeCount) return <NotificationBadge count={link.badgeCount} />
    return link.badge
  }, [])

  const canCreatePost = isOwner || isModerator

  const navLinks: NavLinkType[] = [
    {
      id: "home",
      title: t("root.home"),
      icon: homeIcon,
      to: "/channels/posts",
      badgeCount: unreadActivities?.home
    },
    {
      id: "create-post",
      title: t("root.create_post"),
      icon: createPostIcon,
      onClick: () => drawers.post.handler.open(),
      badgeCount: unreadActivities?.all,
      hide: !canCreatePost
    },
    {
      id: "notifications",
      title: t("root.notifications"),
      icon: bellIcon,
      onClick: drawers.notifications.show.open,
      badgeCount: unreadActivities?.all
    },
    {
      id: "direct",
      title: t("root.messages"),
      icon: directIcon,
      to: "/messages",
      badgeCount: unReadMessagesCount
    },
    {
      id: "events",
      title: t("root.events"),
      icon: calendarIcon,
      to: "/events",
      badge: hasEventInProgress && <EventBadge />
    },
    {
      id: "metrics-link",
      title: t("root.metrics"),
      icon: analyticsIcon,
      to: "/metrics",
      hide: !isOwner
    },
    {
      id: "groups-and-members-link",
      title: t("root.members"),
      icon: peopleIcon,
      to: "/members",
      badgeCount: unreadMemberCount?.count
    },
    {
      id: "saved-link",
      title: t("root.saved"),
      icon: bookmarkIcon,
      to: "/posts/saved"
    }
  ]

  const memoizedNavLinks = useMemo(() => {
    return navLinks
      .filter(link => !link.hide)
      .map(link => ({
        ...link,
        isActive: pathname === link.to,
        rightSection: renderBadge(link)
      }))
  }, [navLinks, pathname, renderBadge])

  return memoizedNavLinks
}
