import { zodResolver } from "@mantine/form"
import { TFunction } from "i18next"
import { z } from "zod"
import { onlyNumbers } from "../utils/onlyNumbers"

export const planSchema = (t: TFunction<"translation", undefined>) => {
  const schema = z.object({
    description: z
      .string()
      .min(3, { message: t("validation.field_min_size", { size: 3 }) }),
    price_cents: z.string().refine(
      value => Number(onlyNumbers(value)) >= 1000,
      () => ({ message: t("common.min_price", { value: "R$ 10,00" }) })
    )
  })
  return zodResolver(schema)
}
