import { MS_PAYMENTS_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"

export const planDestroy = async (id: string) => {
  const endpoint = `${MS_PAYMENTS_URL}/destroy-plan?tenant=${getTenant()}&id=${id}`
  const { access_token } = await getSession()

  const response = await fetch(endpoint, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    }
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
}
