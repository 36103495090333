import { useDisclosure } from "@mantine/hooks"
import { Collapse } from "@mantine/core"
import {
  DsGroup,
  DsStackVertical,
  DsText,
  DsButtonUnstyled,
  DsSpace,
  DsRadio,
  DsDividerBase,
  DsBox,
  DsFlex,
  DsCheckbox
} from "@raisesistemas/ds"

import { GlobeIcon, IconIosArrowDown, IconIosArrowUp } from "../../icons"
import { PostPreview } from "./PostItem/PostPreview"
import { usePostHandler } from "./PostHandlerProvider"
import { useIsScreen } from "../../hooks/useIsScreen"
import { usePlanQueries } from "../../hooks/queries/usePlanQueries"
import { formatMoneyInCents } from "../../utils/formatMoneyInCents"
import { planPostsSelect } from "../../api/planPosts/planPostsSelect"
import { PermissionType } from "../../api/post/postCreate"

export function PostHandlerPermissions() {
  const [opened, { toggle }] = useDisclosure(true)
  const { form, content } = usePostHandler()
  const { data: plans } = usePlanQueries().list()
  const { isMobile } = useIsScreen()

  const handleSelectOption = (option: PermissionType) => {
    form.setFieldValue("permissionType", option)
    if (option !== "select_plans") {
      form.setFieldValue("plansId", [])
    }
    planPostsSelect({ postId: form.values.postId || "" })
  }

  const handlePlanChange = (planId: string) => {
    const selectedPlans = form.values.plansId.includes(planId)
      ? form.values.plansId.filter(id => id !== planId)
      : [...form.values.plansId, planId]
    form.setFieldValue("plansId", selectedPlans)
  }

  const plansName = form.values.plansId
    .map(id => {
      const plan = plans?.find(plan => plan.id === id)
      return plan?.data.description
    })
    .filter(Boolean)
    .join(", ")

  const permissionTitles: Record<PermissionType, string> = {
    public: "Público",
    all_members: "Todos os membros",
    only_members_paid: "Somente membros pagantes",
    select_plans: "Planos"
  }

  const getDynamicTitle = (option: PermissionType): string => {
    return option === "select_plans"
      ? `Planos: ${plansName}`
      : permissionTitles[option]
  }

  const dynamicTitle = getDynamicTitle(form.values.permissionType)

  const descriptions: Record<PermissionType, string> = {
    public:
      "Este post ficará visível para todos, até para aqueles de fora da Luminy.",
    all_members: "Todos os membros da comunidade poderão ver este post.",
    only_members_paid:
      "Somente membros pagantes poderão interagir com esse post.",
    select_plans: `Somente os membros dos planos ${plansName} poderão ver este post.`
  }

  const description = descriptions[form.values.permissionType]

  return (
    <DsGroup align="start">
      <DsBox style={{ flex: 1 }}>
        <DsGroup position="center">
          <GlobeIcon size={24} />
          <DsBox style={{ flex: 1 }}>
            <DsStackVertical spacing={2}>
              <DsText variant="body-2" weight="medium">
                Quem pode ver o post
              </DsText>
              <DsText variant="body-3">{dynamicTitle}</DsText>
            </DsStackVertical>
          </DsBox>

          <DsButtonUnstyled onClick={toggle}>
            {opened ? (
              <IconIosArrowUp size={24} />
            ) : (
              <IconIosArrowDown size={24} />
            )}
          </DsButtonUnstyled>
        </DsGroup>

        <Collapse in={opened} transitionDuration={800}>
          <DsSpace height={12} />

          <DsStackVertical>
            <DsButtonUnstyled onClick={() => handleSelectOption("public")}>
              <DsFlex justify="space-between" align="start">
                <DsText variant="body-3" weight="medium">
                  Público
                </DsText>
                <DsRadio
                  checked={form.values.permissionType === "public"}
                  onChange={() => {}}
                />
              </DsFlex>
            </DsButtonUnstyled>

            <DsButtonUnstyled onClick={() => handleSelectOption("all_members")}>
              <DsFlex justify="space-between">
                <DsText variant="body-3" weight="medium">
                  Todos os membros
                </DsText>
                <DsRadio
                  checked={form.values.permissionType === "all_members"}
                  onChange={() => {}}
                />
              </DsFlex>
            </DsButtonUnstyled>

            <DsButtonUnstyled
              onClick={() => handleSelectOption("only_members_paid")}
            >
              <DsFlex justify="space-between">
                <DsText variant="body-3" weight="medium">
                  Somente membros pagantes
                </DsText>
                <DsRadio
                  checked={form.values.permissionType === "only_members_paid"}
                  onChange={() => {}}
                />
              </DsFlex>
            </DsButtonUnstyled>

            <DsButtonUnstyled
              onClick={() => handleSelectOption("select_plans")}
            >
              <DsFlex justify="space-between">
                <DsText variant="body-3" weight="medium">
                  Selecionar planos
                </DsText>
                <DsRadio
                  checked={form.values.permissionType === "select_plans"}
                  onChange={() => {}}
                />
              </DsFlex>
            </DsButtonUnstyled>

            {form.values.permissionType === "select_plans" && (
              <>
                {plans &&
                  plans.map(plan => (
                    <DsButtonUnstyled
                      onClick={() => handlePlanChange(plan.id)}
                      key={plan.id}
                    >
                      <DsFlex justify="space-between">
                        <DsStackVertical spacing={0}>
                          <DsText variant="body-3" weight="bold">
                            {plan.data.description}
                          </DsText>
                          <DsText
                            variant="body-3"
                            weight="regular"
                            color="dimmed"
                          >
                            {`${formatMoneyInCents(plan.data.price_cents)}/${
                              plan.data.interval === "months" ? "Mês" : "Ano"
                            } • ${plan.data.subscriptionCount} ${
                              plan.data.subscriptionCount === 1
                                ? "Membro"
                                : "Membros"
                            }`}
                          </DsText>
                        </DsStackVertical>
                        <DsCheckbox
                          checked={form.values.plansId.includes(plan.id)}
                          onChange={() => handlePlanChange(plan.id)}
                        />
                      </DsFlex>
                    </DsButtonUnstyled>
                  ))}
              </>
            )}

            <DsDividerBase />

            <DsText variant="body-3">{description}</DsText>
          </DsStackVertical>
          <DsSpace height={12} />
        </Collapse>
      </DsBox>

      {!isMobile && (
        <PostPreview
          post={{
            content,
            thumbnail: form.values.thumbnail
          }}
        />
      )}
    </DsGroup>
  )
}
