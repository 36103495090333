import { supabase } from "../../lib/supabase"
import { getTenant } from "../../utils/getTenant"

export type PostSelectPayload = {
  postId: string
}

type PlanPost = {
  id: string
  parent_id: string
  child_id: string
}

export async function planPostsSelect(payload: PostSelectPayload) {
  const tenant = getTenant()

  const { data: plansPosts, error } = await supabase
    .from("db")
    .select("id::text, parent_id::text, child_id::text")
    .match({ tenant, child_id: payload.postId, kind: "plan_post" })
    .returns<PlanPost[]>()

  if (error) throw error

  return plansPosts
}
