import { MS_PAYMENTS_URL } from "../../env"
import { getTenant } from "../../utils/getTenant"
import { getSession } from "../auth/getSession"

type SubscriptionCancelPayload = {
  memberId: string
  plan_id: string
}

export const subscriptionCancel = async (
  payload: SubscriptionCancelPayload
) => {
  const { memberId, plan_id } = payload
  const { access_token } = await getSession()

  const tenant = getTenant()

  if (!plan_id) throw new Error("Missing plan_id")
  const url = new URL(`${MS_PAYMENTS_URL}/cancel/${tenant}`)
  url.searchParams.set("memberId", memberId)
  url.searchParams.set("plan_id", plan_id)

  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    }
  })

  if (!response.ok) throw new Error("Error canceling subscription")
}
