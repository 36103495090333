import { DsGroup, rem } from "@raisesistemas/ds"
import { createRandomId } from "../../utils/createRandomId"
import { Skeleton } from "@mantine/core"

export const BalanceSkeleton = () => {
  return (
    <DsGroup align="center" key={createRandomId()}>
      <Skeleton
        height={16}
        radius="lg"
        mt={20}
        style={{ maxWidth: rem(200) }}
      />
      <Skeleton height={40} radius="lg" />
      <Skeleton
        height={16}
        radius="lg"
        mb={20}
        style={{ maxWidth: rem(200) }}
      />
    </DsGroup>
  )
}
