import {
  DsAvatarGroup,
  DsBox,
  DsButtonPrimary,
  DsSpace,
  DsStackVertical,
  DsText,
  createStyles,
  rem
} from "@raisesistemas/ds"
import { useTranslation } from "react-i18next"
import { useMemberQueries } from "../../hooks/queries/useMemberQueries"
import { memberPresenter } from "../../presenter"
import { IconAiEssential, LockOpen } from "../../icons"
import { useThemeColoring } from "../../hooks/useThemeColoring"
import { useNav } from "../../hooks/useNav"

export const RequestAccess = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { colors, lightDark } = useThemeColoring()
  const memberQueries = useMemberQueries()
  const { members } = memberQueries.listAll()
  const navigate = useNav()

  return (
    <DsStackVertical
      className={classes.root}
      align="center"
      justify="center"
      spacing={0}
      data-testid={REQUEST_ACCESS_ID}
    >
      <DsBox className={classes.iconBox}>
        <IconAiEssential />
      </DsBox>

      <DsSpace height="md" />

      <DsText
        variant="heading-3"
        weight="medium"
        color={lightDark("gray.0", "gray.0")}
      >
        {t("root.ai")}
      </DsText>

      <DsSpace height={8} />

      <DsText
        variant="body-2"
        color={lightDark("gray.0", "gray.0")}
        weight="regular"
      >
        {t("description.request_access_button_ai")}
      </DsText>

      <DsSpace height="md" />

      <DsAvatarGroup
        users={memberPresenter.forCardAtavar(members)}
        avatarSize={40}
      />

      <DsSpace height={32} />

      <DsButtonPrimary
        leftIcon={<LockOpen color={colors.gray[0]} />}
        onClick={() => navigate("/settings/plan")}
        className={classes.button}
        data-testid={REQUEST_ACCESS_BUTTON_UNLOCK_ID}
      >
        <DsText
          variant="body-1"
          color={lightDark("gray.0", "gray.0")}
          weight="medium"
        >
          {t("button.unlock_channel_access")}
        </DsText>
      </DsButtonPrimary>
    </DsStackVertical>
  )
}
export const REQUEST_ACCESS_ID = "request-access-id"
export const REQUEST_ACCESS_BUTTON_UNLOCK_ID = "request-access-button-unlock-id"

const useStyles = createStyles(({ colors }) => ({
  root: {
    padding: rem(30),
    height: "100%",
    textAlign: "center"
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: rem(48),
    height: rem(48),
    backgroundColor: colors.gray[0],
    borderRadius: rem(24)
  },
  button: {
    borderRadius: rem(8),
    padding: `${rem(4)} ${rem(18)}`,
    height: rem(44),
    background: colors.dark[9],
    borderWidth: rem(1),
    borderStyle: "solid",
    borderColor: "#E2E8F0",
    ":hover": {
      backgroundColor: colors["dark"][7]
    }
  }
}))
