import { BankAccountInfoDrawer } from "./components/BankAccountInfoDrawer"
import { ChannelCreateDrawer } from "./components/Channel/ChannelCreateDrawer"
import { ChannelEditDrawer } from "./components/Channel/ChannelEditDrawer"
import { ChannelTopicCreateDrawer } from "./components/Channel/ChannelTopicCreateDrawer"
import { ChannelTopicEditDrawer } from "./components/Channel/ChannelTopicEditDrawer"
import { EventHandlerDrawer } from "./components/Events/EventHandlerDrawer"
import { EventViewDrawer } from "./components/Events/EventViewDrawer"
import { ExtractDrawer } from "./components/ExtractDrawer"
import { ExtractSimpleDrawer } from "./components/ExtractDrawer/ExtractSimpleDrawer"
import { LikeListDrawer } from "./components/Like/LikeListDrawer"
import { MemberDetailDrawer } from "./components/Member/MemberDetail/MemberDetailDrawer"
import { MemberInviteByCSVDrawer } from "./components/Member/MemberInviteByEmailDrawers/MemberInviteByCSVDrawer"
import { MemberInviteByEmailDrawer } from "./components/Member/MemberInviteByEmailDrawers/MemberInviteByEmailDrawer"
import { MemberInviteMethodSelectorDrawer } from "./components/Member/MemberInviteMethodSelectorDrawer"
import { MemberPermissionDrawer } from "./components/Member/MemberPermissionsDrawer"
import { ModalPrice } from "./components/ModalPrice"
import { NotificationDrawer } from "./components/Notifications/NotificationDrawer"
import { NotificationsLatestDrawer } from "./components/Notifications/NotificationsLatestDrawer"
import { NotificationsManagerDrawer } from "./components/Notifications/NotificationsManagerDrawer"
import { PostHandlerProvider } from "./components/post/PostHandlerProvider"
import { PostViewDrawer } from "./components/post/PostViewDrawer"
import { PaymentCancelPlanModal } from "./pages/settings/Payment/PaymentMemberSettings/PaymentCancelPlanModal"
import { PaymentEditPlanSheet } from "./pages/settings/Payment/PaymentMemberSettings/PaymentEditPlanSheet"
import { BankEditDrawer } from "./pages/settings/Plan/BankEditDrawer"
import { InsertCPF } from "./pages/settings/Plan/InsertCPF"
import { PlanDetails } from "./pages/settings/Plan/PlanDetails"
import { PlanStepperDrawer } from "./pages/settings/Plan/PlanStepperDrawer"
import { drawers } from "./state"
import { isOpenedPlanDrawer, isOpenedProModal } from "./state/drawer"

export const GlobalDrawers = () => {
  return (
    <>
      <PlanStepperDrawer
        opened={isOpenedPlanDrawer.value}
        onClose={() => (isOpenedPlanDrawer.value = false)}
      />
      <ModalPrice
        opened={isOpenedProModal.value}
        onClose={() => (isOpenedProModal.value = false)}
      />

      {drawers.bank.accountInfo.appendDOM && <BankAccountInfoDrawer />}

      {drawers.event.detail.appendDOM && <EventViewDrawer />}
      {drawers.event.handler.appendDOM && <EventHandlerDrawer />}

      {drawers.extract.full.appendDOM && <ExtractDrawer />}
      {drawers.extract.simple.appendDOM && <ExtractSimpleDrawer />}

      {drawers.like.list.appendDOM && <LikeListDrawer />}

      {drawers.member.detail.appendDOM && <MemberDetailDrawer />}

      {drawers.member.inviteSelector.appendDOM && (
        <MemberInviteMethodSelectorDrawer />
      )}
      {drawers.member.inviteByCSV.appendDOM && <MemberInviteByCSVDrawer />}
      {drawers.member.inviteByEmail.appendDOM && <MemberInviteByEmailDrawer />}
      {drawers.member.permissions.appendDOM && <MemberPermissionDrawer />}

      {drawers.notifications.show.appendDOM && <NotificationDrawer />}
      {drawers.notifications.manager.appendDOM && (
        <NotificationsManagerDrawer />
      )}

      {drawers.notifications.latest.appendDOM && <NotificationsLatestDrawer />}

      {drawers.post.handler.appendDOM && <PostHandlerProvider />}
      {drawers.post.view.appendDOM && <PostViewDrawer />}

      {drawers.topic.create.appendDOM && <ChannelTopicCreateDrawer />}
      {drawers.topic.edit.appendDOM && <ChannelTopicEditDrawer />}

      {drawers.channel.create.appendDOM && <ChannelCreateDrawer />}
      {drawers.channel.edit.appendDOM && <ChannelEditDrawer />}

      {drawers.bank.edit.appendDOM && <BankEditDrawer />}

      {drawers.plan.insertCPF.appendDOM && <InsertCPF />}
      {drawers.plan.detail.appendDOM && <PlanDetails />}

      {drawers.subscription.edit.appendDOM && <PaymentEditPlanSheet />}
      {drawers.subscription.cancel.appendDOM && <PaymentCancelPlanModal />}
    </>
  )
}
