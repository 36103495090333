import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useStandardToast } from "../useStandardToast"
import { useImageUpload } from "../useImageUpload"
import { PostUpdatePayload, postUpdate } from "../../api/post/postUpdate"
import { toast } from "@raisesistemas/ds"
import { queries } from "../../queries"
import { PostCreatePayload, postCreate } from "../../api/post/postCreate"
import { DEFAULT_POST_THUMBNAIL } from "../../lib/constants"
import { postLike } from "../../api/post/postLike"

export const usePostMutations = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { cantFinalize } = useStandardToast()
  const { handleUploadImage } = useImageUpload()

  const create = useMutation({
    mutationFn: async (payload: PostCreatePayload) => {
      const thumbnail =
        (await handleUploadImage(payload.thumbnail, DEFAULT_POST_THUMBNAIL)) ||
        ""
      return await postCreate({ ...payload, thumbnail })
    },
    onSuccess: () => {
      toast.success({
        message: t("toast.post.created")
      })

      queryClient.invalidateQueries({
        queryKey: [queries.posts._def, queries.planPosts._def]
      })
    },
    onError: () => {
      cantFinalize()
    }
  })

  const update = useMutation({
    mutationFn: async (payload: PostUpdatePayload) => {
      const thumbnail = await handleUploadImage(
        payload.data.thumbnail,
        DEFAULT_POST_THUMBNAIL
      )

      const data = Object.assign(payload.data, { thumbnail })
      return await postUpdate({ ...payload, data })
    },
    onSuccess: (_, { id, plansId }) => {
      toast.success({
        message: t("validation.edited_success")
      })

      queryClient.invalidateQueries({
        queryKey: [queries.posts._def, queries.planPosts._def]
      })

      const newPlansPost = plansId?.map(planId => ({
        parent_id: planId,
        child_id: id
      }))

      queryClient.setQueryData(
        queries.planPosts.listByPostId(id).queryKey,
        newPlansPost
      )
    },
    onError: () => {
      cantFinalize()
    }
  })

  const like = useMutation({
    mutationFn: postLike
  })

  return {
    create,
    update,
    like
  }
}
