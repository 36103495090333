import { useState } from "react"
import {
  DsButtonPrimary,
  DsStackVertical,
  DsText,
  DsTextInputDocument,
  toast
} from "@raisesistemas/ds"
import { Sheet } from "../../../components/Sheet"
import { useIsScreen } from "../../../hooks/useIsScreen"
import { onlyNumbers } from "../../../utils/onlyNumbers"
import { useForm } from "@mantine/form"
import { modals } from "@mantine/modals"
import { checkoutUrl } from "../../../api/checkout/checkoutUrl"
import { drawers } from "../../../state"
import { useNav } from "../../../hooks/useNav"

export type InsertCPFProps = {
  planId: string
}

export const InsertCPF = () => {
  const {
    isOpen,
    close,
    props: { planId }
  } = drawers.plan.insertCPF
  const [isLoading, setIsLoading] = useState(false)
  const { isMobile } = useIsScreen()
  const navigate = useNav()

  const { values, getInputProps, validate } = useForm({
    initialValues: {
      document: ""
    },
    validate: {
      document: value =>
        onlyNumbers(value).length === 11 ? null : "CPF inválido"
    }
  })

  const handleRequestAccess = async () => {
    if (validate().hasErrors) return

    setIsLoading(true)

    try {
      const { checkout_url } = await checkoutUrl({
        document: onlyNumbers(values.document),
        planId
      })

      window.open(checkout_url, "_blank")
      modals.close("pre-checkout")
      drawers.plan.insertCPF.close()
      navigate("/settings/payment")
    } catch (error) {
      toast.error({ message: "Não foi possível concluir esta ação." })
    } finally {
      setIsLoading(false)
    }
  }

  const title = (
    <DsText variant="heading-3" weight="bold">
      Insira seu CPF
    </DsText>
  )

  const footer = (
    <DsButtonPrimary
      onClick={() => {
        handleRequestAccess()
      }}
      loading={isLoading}
      //@ts-ignore
      w="100%"
    >
      Ir para o pagamento
    </DsButtonPrimary>
  )

  return (
    <>
      <Sheet
        opened={isOpen}
        onClose={close}
        title={title}
        footer={footer}
        variant={isMobile ? "modal" : "drawer"}
      >
        <DsStackVertical>
          <DsText variant="body-1" weight="regular" color="dimmed">
            Para prosseguirmos precisamos dessa informação para garantir a
            autenticidade de sua assinatura.
          </DsText>

          <DsTextInputDocument
            required
            onlyCPF
            label="CPF"
            {...getInputProps("document")}
          />
        </DsStackVertical>
      </Sheet>
    </>
  )
}
