import { PostModel, PostType } from "../types/post"

export const parsePost = (post: PostType, fixed = false): PostModel => {
  return {
    ...post,
    fixed,
    saved: post.saved.length > 0,
    liked: post.liked.length > 0,
    likesCount: post.likesCount?.[0].count || 0,
    commentsCount: post.commentsCount?.[0].count || 0,
    permissionType: post.permission_type || "public"
  }
}
