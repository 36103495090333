import { supabase } from "../../lib/supabase"
import { SubscriptionType } from "../../types/subscription"
import { getTenant } from "../../utils/getTenant"
import { getUserId } from "../../utils/getUserId"

type SubscriptionFindUniqueProps = {
  memberId?: string
}

export const subscriptionFindUnique = async (
  props: SubscriptionFindUniqueProps = {}
) => {
  const { memberId = await getUserId() } = props
  const tenant = getTenant()

  const { data, error } = await supabase
    .from("db")
    .select("*, parent_id::text, plan:parent_id!inner(id::text, *)")
    .eq("kind", "subscription_member")
    .eq("user_uuid", memberId)
    .eq("tenant", tenant)
    .single<SubscriptionType>()

  if (error) return null

  return data
}
