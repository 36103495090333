import { useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"

export const usePlanQueries = () => {
  const find = () => {
    return useQuery({ ...queries.plans.find(), retry: false })
  }

  const byId = (id: string) => {
    return useQuery(queries.plans.byId(id))
  }

  const list = () => {
    return useQuery(queries.plans.list())
  }

  return { find, list, byId }
}
