import { useQuery } from "@tanstack/react-query"
import { queries } from "../../queries"

export const useSubscriptionQueries = () => {
  const findUnique = () => {
    return useQuery(queries.subscription.findUnique())
  }

  return {
    findUnique
  }
}
