import { mergeQueryKeys } from "@lukemorales/query-key-factory"
import { audienceQueries } from "./audienceQueries"
import { channelQueries } from "./channelQueries"
import { commentQueries } from "./commentQueries"
import { memberQueries } from "./memberQueries"
import { planQueries } from "./planQueries"
import { postQueries } from "./postQueries"
import { replyQueries } from "./replyQueries"
import { spaceQueries } from "./spaceQueries"
import { tenantQueries } from "./tenantQueries"
import { activitiesQueries } from "./activitiesQueries"
import { bankQueries } from "./bankQueries"
import { subscriptionQueries } from "./subscriptionQueries"
import { extractQueries } from "./extractQueries"
import { balanceQueries } from "./balanceQueries"
import { metricsQueries } from "./metricsQueries"
import { eventQueries } from "./eventQueries"
import { topicQueries } from "./topicQueries"
import { postSavedQueries } from "./postSavedQueries"
import { gifQueries } from "./gifQueries"
import { likesQueries } from "./likesQueries"
import { messagesQueries } from "./messagesQueries"
import { directQueries } from "./directQueries"
import { planPostQueries } from "./planPostQueries"

export const queries = mergeQueryKeys(
  planQueries,
  planPostQueries,
  bankQueries,
  postQueries,
  replyQueries,
  eventQueries,
  spaceQueries,
  memberQueries,
  tenantQueries,
  commentQueries,
  channelQueries,
  audienceQueries,
  activitiesQueries,
  subscriptionQueries,
  extractQueries,
  balanceQueries,
  metricsQueries,
  topicQueries,
  postSavedQueries,
  gifQueries,
  likesQueries,
  messagesQueries,
  directQueries
)
