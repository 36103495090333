import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"
import { planSchema } from "../../../validations/planSchema"
import { PlanType } from "../../../types/plan"

export type UsePlanFormProps = {
  plan?: PlanType
}

export const usePlanForm = (props?: UsePlanFormProps) => {
  const { plan } = props || {}
  const { t } = useTranslation()
  const defaultValues = {
    type: "subscription",
    price_cents: plan?.data.price_cents.toString() || "",
    active: true,
    interval: "months",
    description: plan?.data.description || "",
    interval_count: 1,
    trial_period_days: 0,
    fee_paid_by_member: false,
    image: plan?.data.image || "",
    info: plan?.data.info || {}
  }

  return useForm({
    initialValues: defaultValues,
    validate: planSchema(t)
  })
}

export type PlanFormValues = ReturnType<typeof usePlanForm>["values"]
