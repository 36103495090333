import { AppShell, createStyles } from "@mantine/core"
import { DsBox, DsMediaQuery } from "@raisesistemas/ds"
import { Outlet } from "react-router-dom"
import { IS_PRODUCTION } from "../../../lib/constants"
import { FreeTrialBanner } from "../../FreeTrialBanner"
import { Nav } from "../../Nav"
import { GlobalDrawers } from "../../../GlobalDrawers"
import { FloatButtons } from "../../FloatButtons"

import { useThemeColoring } from "../../../hooks/useThemeColoring"

export const DashLayout = () => {
  const { lightDark } = useThemeColoring()
  const { classes } = useStyles()

  return (
    <DsBox sx={{ position: "relative" }}>
      {!IS_PRODUCTION && <FreeTrialBanner />}

      <FloatButtons />

      <AppShell
        navbarOffsetBreakpoint="md"
        padding={0}
        bg={lightDark("#fff", "dark.7")}
        navbar={<Nav />}
        style={{ width: "100%" }}
      >
        <DsMediaQuery largerThan="md" styles={{ paddingTop: 90 }}>
          <DsBox className={classes.wrapper}>
            <Outlet />
            <GlobalDrawers />
          </DsBox>
        </DsMediaQuery>
      </AppShell>
    </DsBox>
  )
}

const useStyles = createStyles({
  wrapper: {
    paddingTop: 0,
    minHeight: "100vh"
  }
})
