import { useElementSize } from "@mantine/hooks"
import { useTranslation } from "react-i18next"
import { DsButtonUnstyled, DsFlex, DsText } from "@raisesistemas/ds"

import { IconChevronDown } from "../../icons"

import { useStyles } from "./Spoiler.style"

type SpoilerProps = {
  onShow: () => void
  maxHeight: number
  children: React.ReactNode
}

export const Spoiler = ({ children, maxHeight, onShow }: SpoilerProps) => {
  const { ref: contentRef, height } = useElementSize()
  const { t } = useTranslation()

  const {
    classes,
    theme: { colors, primaryColor }
  } = useStyles()
  const color = colors[primaryColor][4]
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          maxHeight,
          overflow: "hidden"
        }}
      >
        <div ref={contentRef}>{children}</div>
      </div>
      {height > maxHeight && (
        <div className={classes.spoiler}>
          <DsButtonUnstyled onClick={onShow}>
            <DsFlex justify="center" align="center" gap={16}>
              <DsText variant="body-1" color={color} weight="regular">
                {t("root.see_more")}
              </DsText>
              <IconChevronDown size={24} color={color} />
            </DsFlex>
          </DsButtonUnstyled>
        </div>
      )}
    </div>
  )
}
